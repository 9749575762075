import { Box } from "@mui/material";
import { TermsLogoSmall } from "../../assets/Icons";
import {
  TermsContainer,
  TermsHeader,
  TermsLogoText,
  TermsSubTitle,
  TermsTitle,
  HomeLink,
} from "../style";
import LogoText from "../../assets/images/swipe-lounge.png";
import { StyledDivider } from "../../globalStyle";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { TermsAndPolicySagaActions } from "../../store/termsAndPolicy/saga";
import { TermsAndPolicyTextSelector } from "../../store/termsAndPolicy/selector";
import { useLocation } from "react-router-dom";
import routeConstant from "../../utils/routeConstant";

const TermsAndPolicy = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const termsAndConditionsText = useSelector(TermsAndPolicyTextSelector);

  useEffect(() => {
    const documentType =
      location.pathname === routeConstant.termsConditions
        ? "TermsAndConditions"
        : "PrivacyPolicy";

    dispatch(
      TermsAndPolicySagaActions.getTermsAndPolicyText({
        documentType,
      })
    );
  }, []);

  return (
    <TermsContainer>
      <TermsHeader>
        <HomeLink to={"/"}>
          <TermsLogoSmall />
          <TermsLogoText src={LogoText} />
        </HomeLink>
        <Box>
          <TermsTitle>Terms & Privacy Policy</TermsTitle>
          <TermsSubTitle>We’re here to help you!</TermsSubTitle>
        </Box>
      </TermsHeader>
      <StyledDivider />
      <Box
        sx={{
          padding: "1.875rem 16.56rem",
        }}
        dangerouslySetInnerHTML={{
          __html: termsAndConditionsText?.data?.content,
        }}
      ></Box>
    </TermsContainer>
  );
};
export default TermsAndPolicy;
