import { call, put, select } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { createSliceSaga } from "../saga-helper";
import {
  cancelSubscription,
  getPaymentDetails,
  getPaymentMethods,
  getPlaymentHistory,
  getSession,
} from "../../services/PaymentServices";
import { PaymentReducerAction } from "./slice";
import { PayloadAction } from "@reduxjs/toolkit";
import routeConstant from "../../utils/routeConstant";
import { FilterOptions } from "../swipeFile/types";
import { swipefileFilterOptionSelector } from "../swipeFile/selector";
import { SwipeFileReducerAction } from "../swipeFile/slice";
import * as Sentry from "@sentry/react";
import {
  paymentHistoryListSelector,
  subscriptionSkipLimitSelector,
} from "./selector";
import { paymentHistoryListType, subscriptionSkipLimitType } from "./types";
import { notify, ToastTypes } from "../../utils/helpers";
import { usersModalOptionsSelector } from "../metadata/selector";

const saga = createSliceSaga({
  name: "payment-state",
  caseSagas: {
    *initiateCheckout(
      action: PayloadAction<{
        type: string;
        subscriptionId: string;
        BASE_URL: string;
      }>
    ) {
      try {
        const response: AxiosResponse = yield call(
          getSession,
          action?.payload?.type,
          action?.payload?.subscriptionId
        );

        if (response?.status === 200) {
          const updatedData = response.data.map((res: any) => {
            if (res.returnUrl) {
              return {
                ...res,
                returnUrl: `${action.payload.BASE_URL}${routeConstant?.redirect}?${res.returnUrl}`,
              };
            }
            return res;
          });
          yield put(
            PaymentReducerAction.setPaymentSession([
              updatedData,
              response.status,
            ])
          );
        }
      } catch (error) {
        return error;
      }
    },
    *getPaymentHistoryList() {
      try {
        yield put(PaymentReducerAction.setIsLoading(true));
        const response: AxiosResponse = yield call(getPlaymentHistory);
        if (response?.status === 200) {
          yield put(
            PaymentReducerAction.setPaymentHistory({
              data: response?.data?.data,
              total: response?.data?.total,
            })
          );
          yield put(PaymentReducerAction.setIsLoading(false));
        }
      } catch (error) {
        yield put(PaymentReducerAction.setIsLoading(false));
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *getPaymentDetails(action: {
      payload: {
        id: string;
      };
    }) {
      try {
        yield put(PaymentReducerAction.setIsLoading(true));
        const response: AxiosResponse = yield call(
          getPaymentDetails,
          action.payload.id
        );
        if (response?.status === 200) {
          yield put(PaymentReducerAction.setPaymentDetails(response?.data));
          yield put(PaymentReducerAction.setIsLoading(false));
        }
      } catch (error) {
        yield put(PaymentReducerAction.setIsLoading(false));
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *cancelSubscription(action: { payload: { id: string } }) {
      try {
        // const existingPaymentHistoryList: paymentHistoryListType = yield select(
        //   paymentHistoryListSelector
        // );

        yield put(PaymentReducerAction.setIsLoading(true));

        const response: AxiosResponse = yield call(cancelSubscription);

        if (response?.status === 200) {
          yield put(PaymentSagaActions.getPaymentHistoryList());
          // const updatedData = existingPaymentHistoryList.data?.map((item) => {
          //   if (item._id === action.payload.id) {
          //     return {
          //       ...item,
          //       isPaymentDone: null,
          //     };
          //   }
          //   return item;
          // });
          // yield put(
          //   PaymentReducerAction.setPaymentHistory({
          //     data: updatedData,
          //   })
          // );
          // updatedData
          // const updatedData = existingPaymentHistoryList.data?.filter(
          //   (item: any) => item.isPaymentDone !== null
          // );
          notify({
            message: "Subscription Cancelled!",
            type: ToastTypes.SUCCESS,
          });
          yield put(PaymentReducerAction.setIsLoading(false));
        }
      } catch (error) {
        yield put(PaymentReducerAction.setIsLoading(false));
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *GetPaymentMethods() {
      try {
        yield put(PaymentReducerAction.setIsLoading(true));
        const response: AxiosResponse = yield call(getPaymentMethods);
        if (response?.status === 200) {
          yield put(
            PaymentReducerAction.setPaymentMethods(response?.data?.result?.paymentMethods)
          );
          yield put(PaymentReducerAction.setIsLoading(false));
        }
      } catch (error) {
        yield put(PaymentReducerAction.setIsLoading(false));
        Sentry.captureException(error);
        throw new Error();
      }
    },
  },
  sagaType: "takeEvery",
});

export const {
  actions: PaymentSagaActions,
  saga: PaymentSaga,
  name: PaymentSagaName,
} = saga;
