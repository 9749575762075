import styled from "styled-components";
import { CustomButton } from "../../globalStyle";
import {
  Box,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  Grid,
  Badge,
} from "@mui/material";
import { TextareaAutosize } from "@mui/base";
import { Link } from "react-router-dom";

export const CardContainer = styled(Box)`
  border: 1px solid ${({ theme }) => theme.colors.border1};
  border-radius: 10px;
  padding: 1.25rem;
  background: white;
`;
export const CardContainerUserInfo = styled(Box)`
  border: 1px solid ${({ theme }) => theme.colors.border1};
  border-radius: 10px;
  padding: 1.25rem;
  background: white;
  @media (max-width: 1400px) {
    flex-wrap: wrap;
  }
`;

export const DashboardCardButton = styled(CustomButton)`
  && {
    border: 1px solid ${({ theme }) => theme.colors.border1};
    border-radius: 50px;
    padding-inline: 1.25rem;
    color: ${({ theme }) => theme.colors.black2};
    font-size: 1rem;
    font-weight: 500;
    &:hover {
      background: ${({ theme }) => theme.colors.backgroundPrimary};
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const CardTitle = styled(Typography)`
  && {
    font-size: 1.5rem;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.black2};
  }
`;

export const CardSubTitle = styled(Typography)`
  && {
    font-size: 1rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.gray1};
  }
`;

export const GrayBgHeader = styled(Box)`
  && {
    background-color: ${({ theme }) => theme.colors.background4};
    padding: 1.25rem;
    border-block: 1px solid ${({ theme }) => theme.colors.border1};
  }
`;

export const GrayBgHeaderText = styled(Typography)`
  && {
    font-size: 1.125rem;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.black2};
  }
`;

export const DashboardUserInfo = styled(Box)`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  width: 49%;
  @media (max-width: 1400px) {
    flex: 0 0 60%;
  }
`;
export const SavedAdsDashboardStack = styled(Stack)<{ alignItems: string }>`
  align-items: ${({ alignItems }) => alignItems};
  @media (max-width: 1400px) {
    flex: 0 0 30%;
  }
  @media (min-width: 1401px) and (max-width: 1465px) {
    flex: 0 0 16%;
  }
`;

export const DashboardCardButtonUserInfo = styled(CustomButton)`
  && {
    border: 1px solid ${({ theme }) => theme.colors.border1};
    border-radius: 50px;
    padding-inline: 1.25rem;
    color: ${({ theme }) => theme.colors.black2};
    font-size: 1rem;
    font-weight: 500;
    @media (max-width: 1400px) {
      margin-top: 1rem;
      span {
        margin-right: 8px !important;
      }
      padding-inline: 1rem !important;
      flex: 0 0 45%;
      margin-left: 0;
    }
    @media (min-width: 1401px) and (max-width: 1465px) {
      span {
        margin-right: 4px !important;
      }
      padding-inline: 0.25rem !important;
      flex: 0 0 36%;
    }
  }
`;

export const TeamInviteEmailInput = styled(OutlinedInput)`
  padding: 0.625rem 1.25rem;
  border-radius: 1.25rem !important;
  height: 2.5rem;
  width: 75%;
  background: white;
  fieldset {
    border: 1px solid ${({ theme }) => theme.colors.border1};
  }
`;

export const TeamInviteButton = styled(CustomButton)`
  && {
    border: 1px solid ${({ theme }) => theme.colors.border1};
    border-radius: 1.25rem;
    height: 2.5rem;
    width: 25%;
    padding: 0.714rem 3.143rem;
    color: ${({ theme }) => theme.colors.gray1};
    font-size: 1.143rem;
    font-weight: 400;
    &:hover {
      background: ${({ theme }) => theme.colors.backgroundPrimary};
      color: white;
    }
  }
`;

export const ContactUsWrapper = styled.form`
  display: flex;
  flex-direction: column;
  padding: 1.875rem 0.625rem 0.625rem 0.625rem;
  margin: 0 1.25rem;
  gap: 3.5rem;
`;

export const StyledContactUsInputLabel = styled(InputLabel)`
  && {
    color: ${({ theme }) => theme.colors.black2} !important;
    font-size: 1.071rem;
    font-weight: 400;
  }
`;

export const ContactFormErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.radicalRed};
  font-size: 1rem;
  margin-top: 0.4rem;
  font-weight: bold;
`;

export const TrendingBrandLogoWrapper = styled.div`
  width: 3rem;
  height: 3rem;
  overflow: hidden;
  border-radius: 0.5rem;
`;

export const TrendingBrandWrapper = styled(Grid)`
  display: flex;
  margin-bottom: 1rem !important;
  padding: 1.25rem;
  border: 1px solid ${({ theme }) => theme.colors.border1};
  border-radius: 0.5rem;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1100px) {
    flex-direction: column !important;
    gap: 0.937rem;
  }
`;
export const TrendingBrandCommonWrapper = styled(Box)`
  display: flex;
  gap: 0.625rem;
  align-items: center;
`;

export const MainVideoWrapper = styled(Box)`
  padding: 1rem;
`;
export const ManageTeamInfo = styled(Box)`
  display: flex;
  align-items: center;
  gap: 0.937rem;
`;

export const CreateTeamButton = styled(CustomButton)`
  border-radius: 1.56rem !important;
  color: white !important;
  background: ${({ theme }) => theme.colors.backgroundPrimary} !important;
  height: 2.75rem;
  && {
    padding: 0.625rem 0.937rem;
  }
`;
export const StyledTextArea = styled(TextareaAutosize)`
  border-color: ${({ theme }) => theme.colors.border1} !important;
  padding: 0.75rem 0.875rem;
  border-radius: 6px;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  &:focus {
    border: 2px solid ${({ theme }) => theme.colors.border1} !important;
    outline: none !important;
  }
`;

export const ProfileStatusBadge = styled(Badge)<{ color: string }>`
  && {
    .MuiBadge-dot {
      position: absolute;
      right: -1rem;
      top: -0.03rem;
      background-color: ${({ color }) => color};
    }
  }
`;

export const BuyNowButtonWrapper = styled(Link)`
  background: white !important;
  border-radius: 100px !important;
  border: 0 !important;
  padding: 8px 20px !important;
  text-transform: capitalize !important;
  color: black !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  cursor: pointer !important;
  text-decoration: none !important;
`;

export const TeamsCollectionHeader = styled(Box)`
  && {
    background-color: ${({ theme }) => theme.colors.background4};
    padding: 0.6rem;
    border-block: 1px solid ${({ theme }) => theme.colors.border1};
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const PaymentTypeButton = styled(CustomButton)<{ disabled: boolean }>`
  && {
    font-size: 1rem;
    line-height: 1.4375em;
    color: white;
    border: 1px solid ${({ theme }) => theme.colors.border1};
    border-radius: 30px;
    background: ${({ disabled, theme }) =>
      disabled ? "" : theme.colors.backgroundPrimary};
    padding: 14px 40px;
    height: 38px;
  }
`;
