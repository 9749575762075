import { call, put, select } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { createSliceSaga } from "../saga-helper";
import { MetaDataReducerAction } from "./slice";
import { AxiosResponse } from "axios";
import {
  CreateFolderService,
  DeleteFolder,
  FetchFolderListService,
  FolderAccessMembersService,
  FolderInviteService,
  FolderTeamMembersService,
  FolderTeamShareService,
  UpdateFolder,
  SharedFolderListService,
} from "../../services/FolderService";
import {
  BoardService,
  CreateBoardService,
  DeleteBoardService,
  SaveAdToBoardsService,
  UpdateBoardService,
} from "../../services/BoardServices";
import {
  SaveAdTagsService,
  fetchTagsListService,
} from "../../services/TagsService";
import {
  addBrandToFavouriteService,
  fetchBrandsListService,
  getFavouriteBrands,
} from "../../services/BrandsService";
import {
  addCommentService,
  deleteCommentService,
} from "../../services/CommentServices";
import {
  GenerateTranscriptService,
  downloadMedia,
  getAdByIdService,
  manualUploadAds,
  rateAdByIdService,
  saveTranscript,
} from "../../services/AdServices";
import {
  TagsListsSelector,
  adDetailsSelector,
  boardAdsItemsSelector,
  boardAdsSearchSelector,
  boardItemsSelector,
  favouriteBrandIdsSelector,
  favouriteBrandsSelector,
  filterOptionSelector,
  folderItemSelector,
  folderListsSelector,
  folderTeamMemberListSelector,
  notificationListSelector,
  selectFolderSelector,
  selectedFolderIdSelector,
  selectedSharedFolderIdSelector,
  selectedSharedFolderSelector,
  selectedTabIndexSelector,
  sharedBoardAdsItemsSelector,
  sharedFolderListSelector,
  usersModalOptionsSelector,
} from "./selector";
import {
  BoardAdsItemsType,
  BoardItem,
  FolderTeamMembersListType,
  UserSharedFoldersType,
  adDetailsType,
  brandItem,
  commentParamType,
  folderItem,
  folderLists,
  notificationType,
  tagItem,
} from "./types";
import { userDetailsSelector } from "../account/selector";
import { userDetailType } from "../account/types";
import {
  FilterOptions,
  SwipeFileListItems,
  UserModalFilterOptionsType,
  swipeFileItemListsType,
} from "../swipeFile/types";
import {
  swipefileFilterOptionSelector,
  swipeFileListSelector,
} from "../swipeFile/selector";
import { SwipeFileReducerAction } from "../swipeFile/slice";
import { ToastTypes, notify } from "../../utils/helpers";
import { createBoardParamType } from "../../types/BoardTypes";
import { createFolderParamType } from "../../types/FolderTypes";
import {
  ChangeTeam,
  CreateTeamService,
  DeleteMember,
  JoinTeamService,
  SendInvitationService,
  TeamDetailsService,
  TeamRemoveMemberService,
} from "../../services/TeamService";
import {
  CheckNotification,
  NotificationList,
} from "../../services/NotificationService";
import * as Sentry from "@sentry/react";
import { DiscoveryItem, discoveryItemListsType } from "../discovery/types";
import { discoveryItemsSelector } from "../discovery/selector";
import { DiscoveryReducerAction } from "../discovery/slice";
import { getSwipeFileAdsList } from "../../services/SwipeFileServices";
import { AccountReducerAction } from "../account/slice";

const saga = createSliceSaga({
  name: "metaData-state",
  caseSagas: {
    *createFolder(action: { payload: createFolderParamType; type: string }) {
      try {
        yield put(MetaDataReducerAction.setIsLoading(true));
        const response: AxiosResponse = yield call(
          CreateFolderService,
          action.payload
        );

        if (response?.status === 200) {
          notify({
            message: response?.data?.message,
            type: ToastTypes.SUCCESS,
          });
          const parsedResponse = {
            _id: response?.data?.data?._id,
            name: response?.data?.data?.name,
            boards: [],
          };
          yield put(MetaDataReducerAction.setNewFolderItem(parsedResponse));
          yield put(MetaDataReducerAction.setIsLoading(false));
          yield put(MetaDataReducerAction.setOpenCreateFolder(false));
        }
      } catch (error) {
        Sentry.captureException(error);
        throw new Error();
      } finally {
        yield put(MetaDataReducerAction.setIsLoading(false));
      }
    },
    *fetchFolderListByBoardId(
      action: PayloadAction<{
        selectedFolderId: string;
        selectedTabIndex: number;
        type: string;
        boardId: string;
      }>
    ) {
      const selectedFolderId: null | string = yield select(
        selectedFolderIdSelector
      );
      console.log(action.payload);
      const folderId = action.payload.selectedFolderId
        ? action.payload.selectedFolderId
        : selectedFolderId;

      const filterOptions: FilterOptions = yield select(filterOptionSelector);
      const search: string = yield select(boardAdsSearchSelector);
      yield put(MetaDataReducerAction.setIsLoading(true));

      try {
        const requestPaylaod = {
          ...filterOptions,
          selectedBoardIds: [action.payload.boardId ?? ""],
          search: search ?? "",
          folderId: folderId,
          skip: action.payload.type === "filter" ? 0 : filterOptions.skip,
        };

        let boardResponse: AxiosResponse = yield call(
          getSwipeFileAdsList,
          requestPaylaod
        );

        if (boardResponse.status === 200) {
          yield put(
            MetaDataReducerAction.setFilterOptionSkipValue(
              filterOptions.skip + filterOptions.limit
            )
          );

          const boardItems = boardResponse.data.data;
          boardResponse.data.data = boardItems;

          yield put(MetaDataReducerAction.setBoardAdsItems(boardResponse.data));
        }
      } catch (error) {
        Sentry.captureException(error);
        throw new Error();
      } finally {
        yield put(MetaDataReducerAction.setIsLoading(false));
      }
    },
    *fetchFolderList(
      action: PayloadAction<{
        selectedFolderId: string;
        selectedTabIndex: number;
        type: string;
        boardId: string;
      }>
    ) {
      const selectedFolder: null | undefined | folderItem = yield select(
        selectFolderSelector
      );
      const selectedFolderId: null | string = yield select(
        selectedFolderIdSelector
      );
      const existingBoardAdsItems: BoardAdsItemsType = yield select(
        boardAdsItemsSelector
      );
      const folderId = action.payload.selectedFolderId
        ? action.payload.selectedFolderId
        : selectedFolderId;

      const selectedBoardId = action.payload?.selectedTabIndex
        ? selectedFolder?.boards[action.payload?.selectedTabIndex]?._id
        : "";

      const filterOptions: FilterOptions = yield select(filterOptionSelector);
      const search: string = yield select(boardAdsSearchSelector);
      yield put(MetaDataReducerAction.setIsLoading(true));

      try {
        const response: AxiosResponse = yield call(FetchFolderListService);
        let boardId = selectedBoardId;

        if (response?.status === 200) {
          let folderItem = response.data?.userFolders ?? [];

          if (folderId) {
            folderItem = folderItem?.find(
              (item: folderItem) => item._id === folderId
            );
            if (!boardId) boardId = folderItem?.boards[0]?._id;
            console.log(boardId);

            const requestPaylaod = {
              ...filterOptions,
              selectedBoardIds: [boardId ?? ""],
              search: search ?? "",
              folderId: folderId,
              skip: action.payload.type === "filter" ? 0 : filterOptions.skip,
            };

            let boardResponse: AxiosResponse = yield call(
              getSwipeFileAdsList,
              requestPaylaod
            );

            if (boardResponse.status === 200) {
              yield put(
                MetaDataReducerAction.setFilterOptionSkipValue(
                  filterOptions.skip + filterOptions.limit
                )
              );

              const boardItems = boardResponse.data.data;

              // This code might be useful in future.
              // existingBoardAdsItems.data.length > 0
              //   ? [...existingBoardAdsItems.data, ...boardResponse.data.data]
              //   : boardResponse.data.data;
              boardResponse.data.data = boardItems;

              yield put(
                MetaDataReducerAction.setBoardAdsItems(boardResponse.data)
              );
            } else yield put(MetaDataReducerAction.setBoardAdsItems([]));
          }

          yield put(MetaDataReducerAction.setSelectedFolder(folderItem));
          yield put(MetaDataReducerAction.setSelectedFolderId(folderItem._id));
          yield put(MetaDataReducerAction.setFolderItems(response?.data));
        }
      } catch (error) {
        Sentry.captureException(error);
        throw new Error();
      } finally {
        yield put(MetaDataReducerAction.setIsLoading(false));
      }
    },
    *filterSharedFolderList(
      action: PayloadAction<{
        selectedFolderId: string;
        selectedTabIndex: number;
      }>
    ) {
      const { selectedTabIndex } = action.payload;
      const selectedFolderId: null | string = yield select(
        selectedSharedFolderIdSelector
      );
      const existingBoardAdsItems: BoardAdsItemsType = yield select(
        sharedBoardAdsItemsSelector
      );
      const folderId = action.payload.selectedFolderId
        ? action.payload.selectedFolderId
        : selectedFolderId;
      const filterOptions: FilterOptions = yield select(filterOptionSelector);
      const search: string = yield select(boardAdsSearchSelector);
      yield put(MetaDataReducerAction.setIsLoading(true));

      try {
        const response: AxiosResponse = yield call(SharedFolderListService);
        if (response?.status === 200) {
          const sharedFolderItems = response?.data;
          yield put(MetaDataReducerAction.setSharedFolderList(response?.data));
          const folderBoard = sharedFolderItems?.userSharedFolders.find(
            (item: any) => item._id === folderId
          );
          let boardId;

          if (!boardId)
            boardId = selectedTabIndex
              ? folderBoard?.boards[selectedTabIndex]?._id
              : folderBoard?.boards[0]?._id;

          if (folderBoard) {
            yield put(
              MetaDataReducerAction.setSelectedSharedFolder(folderBoard)
            );
          }

          const requestPaylaod = {
            ...filterOptions,
            selectedBoardIds: [boardId ?? ""],
            folderId: folderId,
            search: search ?? "",
          };

          const boardResponse: AxiosResponse = yield call(
            getSwipeFileAdsList,
            requestPaylaod
          );

          if (boardResponse.status === 200) {
            const boardItems =
              existingBoardAdsItems.data.length > 0
                ? [...existingBoardAdsItems.data, ...boardResponse.data.data]
                : boardResponse.data.data;

            boardResponse.data.data = boardItems;

            yield put(
              MetaDataReducerAction.setSharedBoardAdsItems(boardResponse?.data)
            );
          }
        }
      } catch (error) {
        Sentry.captureException(error);
        throw new Error();
      } finally {
        yield put(MetaDataReducerAction.setIsLoading(false));
      }
    },

    *fetchFolderAccessMembersList(
      action: PayloadAction<{
        folderId: string;
        search?: string;
      }>
    ) {
      try {
        const response: AxiosResponse = yield call(
          FolderAccessMembersService,
          action.payload.folderId,
          action.payload.search
        );
        if (response?.status === 200) {
          yield put(
            MetaDataReducerAction.setFolderAccessMemberList(
              response?.data?.folder[0]?.allUsers
            )
          );
        }
      } catch (error) {
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *fetchFolderTeamMembersList(
      action: PayloadAction<{
        folderId: string;
        search?: string;
      }>
    ) {
      try {
        const filterOptions: UserModalFilterOptionsType = yield select(
          usersModalOptionsSelector
        );

        const existingTeamMembersList: FolderTeamMembersListType = yield select(
          folderTeamMemberListSelector
        );

        const skip =
          existingTeamMembersList.data.length > 0
            ? filterOptions.skip + filterOptions.userLimit!
            : 0;
        yield put(MetaDataReducerAction.setUserOptionLimitValue(skip));
        const response: AxiosResponse = yield call(
          FolderTeamMembersService,
          action.payload.folderId,
          skip,
          filterOptions.userLimit!,
          action.payload.search
        );
        if (response?.status === 200) {
          const teamMembersList =
            existingTeamMembersList.data.length > 0
              ? [...existingTeamMembersList.data, ...response?.data?.users]
              : response?.data?.users;

          yield put(
            MetaDataReducerAction.setFolderTeamMemberList({
              data: teamMembersList,
              total: response?.data?.total,
            })
          );
        }
      } catch (error) {
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *fetchSharedFolderList() {
      try {
        const response: AxiosResponse = yield call(SharedFolderListService);
        if (response?.status === 200) {
          yield put(MetaDataReducerAction.setSharedFolderList(response?.data));
        }
      } catch (error) {
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *removeTeamMember(
      action: PayloadAction<{
        userEmail: string;
        folderId: string;
        userId: string;
      }>
    ) {
      try {
        yield put(
          MetaDataReducerAction.setRemoveTeamMember({
            userId: action.payload.userId,
          })
        );
        const response: AxiosResponse = yield call(
          TeamRemoveMemberService,
          action.payload.userEmail,
          action.payload.folderId
        );
        if (response?.status === 200) {
          yield put(
            MetaDataSagaActions.fetchFolderAccessMembersList({
              folderId: action.payload.folderId,
            })
          );
          yield put(MetaDataReducerAction.setRemoveTeamMember({ userId: "" }));
          notify({
            message: "Team Member removed Successfully!",
            type: ToastTypes.SUCCESS,
          });
        }
      } catch (error) {
        Sentry.captureException(error);
        throw new Error();
      } finally {
        yield put(MetaDataReducerAction.setRemoveTeamMember({ userId: "" }));
      }
    },
    *shareFolderWithTeam(
      action: PayloadAction<{
        folderId: string;
      }>
    ) {
      try {
        const response: AxiosResponse = yield call(
          FolderTeamShareService,
          action.payload.folderId
        );

        if (response?.status === 200) {
          yield put(
            MetaDataSagaActions.fetchFolderAccessMembersList({
              folderId: action.payload.folderId,
            })
          );
          notify({
            message: response?.data?.message,
            type: ToastTypes.SUCCESS,
          });
        }
      } catch (error) {
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *sendFolderInvite(
      action: PayloadAction<{
        folderId: string;
        userEmail: string;
      }>
    ) {
      yield put(MetaDataReducerAction.setIsLoading(true));
      try {
        const response: AxiosResponse = yield call(
          FolderInviteService,
          action.payload.folderId,
          action.payload.userEmail
        );
        if (response?.status === 200) {
          yield put(
            MetaDataSagaActions.fetchFolderAccessMembersList({
              folderId: action.payload.folderId,
            })
          );
          notify({
            message: "Folder Invitation sent successfully!",
            type: ToastTypes.SUCCESS,
          });
        }
      } catch (error) {
        Sentry.captureException(error);
        throw new Error();
      } finally {
        yield put(MetaDataReducerAction.setIsLoading(false));
      }
    },
    *fetchFavouriteFolderList() {
      try {
        const response: AxiosResponse = yield call(FetchFolderListService, {
          favourite: true,
        });

        if (response?.status === 200) {
          yield put(
            MetaDataReducerAction.setFavouriteFolderList(response?.data?.data)
          );
        }
      } catch (error) {
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *fetchBoardList(action: PayloadAction<{ search?: string }>) {
      try {
        const boardList: BoardItem[] = yield select(boardItemsSelector);
        if (action.payload || boardList.length === 0) {
          const response: AxiosResponse = yield call(
            BoardService,
            action.payload
          );

          if (response?.status === 200) {
            yield put(
              MetaDataReducerAction.setBoardItems(response?.data?.data)
            );
          }
        }
      } catch (error) {
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *createBoard(action: {
      payload: { values: createBoardParamType; handleLoadingState: Function };
      type: string;
    }) {
      try {
        const response: AxiosResponse = yield call(
          CreateBoardService,
          action.payload.values
        );

        if (response?.status === 200) {
          notify({
            message: response?.data?.message,
            type: ToastTypes.SUCCESS,
          });

          const addNewBoard = {
            _id: response?.data?.data?._id,
            name: response?.data?.data?.name,
          };

          const addNewBoardToFolderItem = {
            _id: response?.data?.data?._id,
            name: response?.data?.data?.name,
            description: response?.data?.data?.description,
            folderId: response?.data?.data?.folderId,
          };

          const folderList: folderLists = yield select(folderListsSelector);

          const selectedFolderId: string = yield select(
            selectedFolderIdSelector
          );

          const updateFolderBoards = (
            folders: folderItem[],
            addNewBoardToFolderItem: {
              _id: string;
              name: string;
              description: string;
              folderId: string;
            }
          ) => {
            return folders.map((item: folderItem) => {
              if (item._id === addNewBoardToFolderItem.folderId) {
                return {
                  ...item,
                  boards:
                    Array.isArray(item?.boards) && item?.boards.length > 0
                      ? [
                          ...item.boards,
                          {
                            _id: addNewBoardToFolderItem._id,
                            name: addNewBoardToFolderItem.name,
                            description: addNewBoardToFolderItem.description,
                          },
                        ]
                      : [
                          {
                            _id: addNewBoardToFolderItem._id,
                            name: addNewBoardToFolderItem.name,
                            description: addNewBoardToFolderItem.description,
                          },
                        ],
                };
              } else {
                return item;
              }
            });
          };

          const updateUserFolders = updateFolderBoards(
            folderList.userFolders,
            addNewBoardToFolderItem
          );
          const updateTeamFolders = updateFolderBoards(
            folderList.teamFolders,
            addNewBoardToFolderItem
          );

          const folderLists = {
            userFolders: updateUserFolders,
            teamFolders: updateTeamFolders,
          };

          yield put(MetaDataReducerAction.setBoardItem(addNewBoard));

          yield put(MetaDataReducerAction.setFolderItems(folderLists));

          yield put(MetaDataReducerAction.setOpenBoardModal(false));

          if (selectedFolderId) {
            const getActiveSelectedFolder = updateUserFolders.find(
              (folder: folderItem) => folder._id === selectedFolderId
            );

            yield put(
              MetaDataReducerAction.setSelectedFolder(getActiveSelectedFolder)
            );
          }
        }

        action.payload?.handleLoadingState &&
          action.payload?.handleLoadingState();
      } catch (error) {
        action.payload?.handleLoadingState &&
          action.payload?.handleLoadingState();
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *fetchTagLists(action: PayloadAction<{ searchValue?: string }>) {
      try {
        let tagsLists: tagItem[] = yield select(TagsListsSelector);
        if (action.payload || tagsLists.length === 0) {
          const response: AxiosResponse = yield call(fetchTagsListService, {
            tag: action.payload?.searchValue ?? "",
          });
          if (response?.status === 200) {
            yield put(MetaDataReducerAction.setTagsList(response?.data?.data));
          }
        }
      } catch (error) {
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *saveAdToBoards(action: {
      payload: {
        adId: string;
        boardIds: string[];
        removeBoardIds: string[];
        reFetchData: Function;
      };
      type: string;
    }) {
      try {
        const { adId, boardIds, removeBoardIds, reFetchData } = action.payload;

        yield put(MetaDataReducerAction.setIsLoading(true));

        const reqBody = {
          adId,
          boardIds,
          removeBoardIds,
        };
        const response: AxiosResponse = yield call(
          SaveAdToBoardsService,
          reqBody
        );

        if (response?.status === 200) {
          notify({
            message: response?.data?.message,
            type: ToastTypes.SUCCESS,
          });
          let adDetailsData: adDetailsType = yield select(adDetailsSelector);
          let boardItems: BoardItem[] = yield select(boardItemsSelector);
          const updatedBoardList = boardItems.filter((item) => {
            if (boardIds.includes(item._id)) {
              return { _id: item._id, name: item.name };
            }
          });
          yield put(
            MetaDataReducerAction.setAdDetails({
              ...adDetailsData,
              board: updatedBoardList,
            })
          );
          yield put(MetaDataReducerAction.setSelectedSaveAdId(""));
          yield put(MetaDataReducerAction.setOpenBoardDropdown("false"));
          yield put(MetaDataReducerAction.setSaveAdPopupAnchorEl(null));
          yield put(MetaDataReducerAction.setBoardIdsToInitialSet());
          reFetchData && reFetchData(adId, updatedBoardList);
        }
        yield put(MetaDataReducerAction.setIsLoading(false));
      } catch (error) {
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *fetchBrandLists(
      action: PayloadAction<{ search?: string; userId?: string }>
    ) {
      try {
        const response: AxiosResponse = yield call(
          fetchBrandsListService,
          action.payload
        );
        if (response?.status === 200) {
          yield put(MetaDataReducerAction.setBrandsList(response?.data?.data));
        }
      } catch (error) {
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *addComment(action: { payload: commentParamType; type: string }) {
      try {
        const response: AxiosResponse = yield call(
          addCommentService,
          action.payload
        );

        if (response?.status === 200) {
          notify({
            message: response?.data?.message,
            type: ToastTypes.SUCCESS,
          });
          const newAddedComment = response?.data?.data;
          let adDetailsData: adDetailsType = yield select(adDetailsSelector);
          let userDetails: userDetailType = yield select(userDetailsSelector);

          adDetailsData = {
            ...adDetailsData,
            Comments: [
              ...(adDetailsData?.Comments ?? []),
              {
                _id: newAddedComment?._id,
                userId: userDetails?._id,
                comment: newAddedComment?.comment,
                user: {
                  fullName: userDetails?.fullName ?? "",
                  profileImage: userDetails?.profileImage ?? "",
                },
                createdAt: newAddedComment?.createdAt,
              },
            ],
          };
          yield put(MetaDataReducerAction.setAdDetails(adDetailsData));
        }
      } catch (error) {
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *fetchAdById(action: { payload: string; type: string }) {
      try {
        yield put(MetaDataReducerAction.setIsLoading(true));
        const response: AxiosResponse = yield call(
          getAdByIdService,
          action.payload
        );
        if (response?.status === 200) {
          const responseData = response?.data?.data;
          yield put(MetaDataReducerAction.setAdDetails(responseData));
          yield put(MetaDataReducerAction.setIsLoading(false));
        }
      } catch (error) {
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *deleteCommentById(action: { payload: string; type: string }) {
      try {
        const response: AxiosResponse = yield call(
          deleteCommentService,
          action.payload
        );
        if (response?.status === 200) {
          notify({
            message: response?.data?.message,
            type: ToastTypes.SUCCESS,
          });
          let adDetailsData: adDetailsType = yield select(adDetailsSelector);
          adDetailsData = {
            ...adDetailsData,
            Comments: adDetailsData?.Comments?.filter(
              (item) => item._id !== action.payload
            ),
          };
          yield put(MetaDataReducerAction.setAdDetails(adDetailsData));
        }
      } catch (error) {
        yield put(MetaDataReducerAction.setIsLoading(false));
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *addBrandToFavourite(action: {
      payload: {
        _id: string;
        name: string;
        logo: string;
        url: string;
        adsSaved: number;
      };
      type: string;
    }) {
      try {
        const brandId = action.payload?._id;
        yield put(MetaDataReducerAction.setIsLoading(true));
        const response: AxiosResponse = yield call(addBrandToFavouriteService, {
          brandId,
        });
        if (response?.status === 200) {
          notify({
            message: response?.data?.message,
            type: ToastTypes.SUCCESS,
          });
          //Remove/add id from favorite Brands ID list
          let favouriteBrandIds: string[] = yield select(
            favouriteBrandIdsSelector
          );
          favouriteBrandIds = favouriteBrandIds.includes(brandId)
            ? favouriteBrandIds.filter((item) => item !== brandId)
            : [...favouriteBrandIds, brandId];
          yield put(
            MetaDataReducerAction.setFavouriteBrandsIds(favouriteBrandIds)
          );

          //Remove/add item from favorite Brand list
          let favouriteBrands: brandItem[] = yield select(
            favouriteBrandsSelector
          );
          const index = favouriteBrands.findIndex((obj) => obj._id === brandId);
          favouriteBrands =
            index !== -1
              ? favouriteBrands.filter((item) => item._id !== brandId)
              : [...favouriteBrands, action.payload];

          yield put(MetaDataReducerAction.setFavouriteBrands(favouriteBrands));

          yield put(MetaDataReducerAction.setIsLoading(false));
        }
      } catch (error) {
        yield put(MetaDataReducerAction.setIsLoading(false));
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *fetchFavouriteBrands() {
      try {
        let favouriteBrands: brandItem[] = yield select(
          favouriteBrandsSelector
        );
        if (favouriteBrands?.length === 0) {
          const response: AxiosResponse = yield call(getFavouriteBrands);
          if (response?.status === 200) {
            const favouriteBrandIds = response?.data?.data?.map(
              (item: brandItem) => item._id
            );
            yield put(
              MetaDataReducerAction.setFavouriteBrandsIds(favouriteBrandIds)
            );
            yield put(
              MetaDataReducerAction.setFavouriteBrands(response?.data?.data)
            );
          }
        }
      } catch (error) {
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *rateAdById(action: {
      payload: {
        adId: string;
        rating: number;
      };
      type: string;
    }) {
      try {
        const response: AxiosResponse = yield call(
          rateAdByIdService,
          action.payload
        );
        if (response?.status === 200) {
          notify({
            message: response?.data?.message,
            type: ToastTypes.SUCCESS,
          });
          let adDetailsData: adDetailsType = yield select(adDetailsSelector);
          adDetailsData = {
            ...adDetailsData,
            ratings: [response?.data?.data],
          };
          yield put(MetaDataReducerAction.setAdDetails(adDetailsData));
          yield put(
            SwipeFileReducerAction.setFolderItemUpdatedRating({
              adId: action.payload.adId,
              ratings: [response?.data?.data],
            })
          );
        }
      } catch (error) {
        yield put(MetaDataReducerAction.setIsLoading(false));
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *clearAllFilter(action: PayloadAction<{ userId?: string }>) {
      try {
        yield put(MetaDataReducerAction.setBrandFilterSearchText(""));
        yield put(
          MetaDataSagaActions.fetchBrandLists({
            search: "",
            ...(action.payload.userId && { userId: action.payload.userId }),
          })
        );
        yield put(MetaDataReducerAction.setBoardFilterSearchText(""));
        yield put(MetaDataSagaActions.fetchBoardList({ search: "" }));
      } catch (error) {
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *downloadMediaZipFile(
      action: PayloadAction<{ adId: string; page: string }>
    ) {
      try {
        yield put(MetaDataReducerAction.setDownloadMediaLoader(true));
        const response: AxiosResponse = yield call(
          downloadMedia,
          action.payload.adId,
          action.payload.page
        );
        if (response?.status === 200) {
          yield put(MetaDataReducerAction.setDownloadMediaLoader(false));
          notify({
            message: "Download successful",
            type: ToastTypes.SUCCESS,
          });
        }
      } catch (error) {
        yield put(MetaDataReducerAction.setDownloadMediaLoader(false));
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *saveAdTranscript(action: {
      payload: {
        name: string;
        adId: string;
        handleResetState: Function;
      };
      type: string;
    }) {
      try {
        yield put(MetaDataReducerAction.setSaveTranscriptLoader(true));
        const response: AxiosResponse = yield call(
          saveTranscript,
          action.payload
        );

        if (response?.status === 200) {
          notify({
            message: response?.data?.message,
            type: ToastTypes.SUCCESS,
          });

          yield put(MetaDataReducerAction.setSaveTranscriptLoader(false));
          yield put(MetaDataReducerAction.setOpenSaveTranscriptModal(false));
          action.payload.handleResetState();
        }
      } catch (error) {
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *manualUploadAd(action: {
      payload: {
        body: {
          boardIds: string[];
          media: File;
        };
      };
      type: string;
    }) {
      try {
        yield put(MetaDataReducerAction.setOpenSaveAdLoader(true));
        const response: AxiosResponse = yield call(
          manualUploadAds,
          action.payload.body
        );
        if (response?.status === 200) {
          yield put(SwipeFileReducerAction.setManualAd(response?.data?.data));
          yield put(MetaDataReducerAction.setOpenSaveAdLoader(false));
          yield put(MetaDataReducerAction.setOpenSaveNewAd(false));

          notify({
            message: response?.data?.message,
            type: ToastTypes.SUCCESS,
          });
        }
      } catch (error) {
        yield put(MetaDataReducerAction.setOpenSaveAdLoader(false));
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *updateFavouriteFolder(action: { payload: string; type: string }) {
      const response: AxiosResponse = yield call(UpdateFolder, action.payload);
      const folderLists: folderLists = yield select(folderListsSelector);
      if (response?.status === 200) {
        const updateFolderList = (folderList: folderItem[]) => {
          return folderList.map((item: folderItem) => {
            if (item._id === action.payload) {
              return {
                ...item,
                favourite: !item.favourite,
              };
            } else {
              return item;
            }
          });
        };

        const updatedUserFolders = updateFolderList(folderLists.userFolders);
        const updatedTeamFolders = updateFolderList(folderLists.teamFolders);

        const updateFolderLists = {
          userFolders: updatedUserFolders,
          teamFolders: updatedTeamFolders,
        };
        yield put(MetaDataReducerAction.setFolderItems(updateFolderLists));
        notify({
          message: response?.data?.message,
          type: ToastTypes.SUCCESS,
        });
      }
    },
    *updateFolder(action: {
      payload: {
        name: string;
        folderId: string;
      };
      type: string;
    }) {
      try {
        yield put(MetaDataReducerAction.setIsLoading(true));
        const response: AxiosResponse = yield call(
          UpdateFolder,
          action.payload.folderId,
          action.payload.name
        );
        if (response?.status === 200) {
          const folderLists: folderLists = yield select(folderListsSelector);

          const updateFolderData = (folderList: folderItem[]) => {
            return folderList?.map((item: folderItem) => {
              if (item._id === action.payload.folderId) {
                return {
                  ...item,
                  name: action.payload.name,
                };
              } else {
                return item;
              }
            });
          };

          const updateUserFolders = updateFolderData(folderLists.userFolders);

          const updateTeamFolders = updateFolderData(folderLists.teamFolders);

          const updatedFolderList = {
            userFolders: updateUserFolders,
            teamFolders: updateTeamFolders,
          };
          yield put(MetaDataReducerAction.setFolderItems(updatedFolderList));
          yield put(MetaDataReducerAction.setFolderItem(null));
          yield put(MetaDataReducerAction.setIsLoading(false));
          yield put(MetaDataReducerAction.setOpenCreateFolder(false));
          notify({
            message: response?.data?.message,
            type: ToastTypes.SUCCESS,
          });
        }
      } catch (error) {
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *deleteFolder(action: {
      payload: {
        closeAnchorElAfterDelete: Function;
      };
      type: string;
    }) {
      try {
        yield put(MetaDataReducerAction.setDeleteFolderLoading(true));
        const folderItem: folderItem = yield select(folderItemSelector);
        const response: AxiosResponse = yield call(
          DeleteFolder,
          folderItem._id
        );
        if (response?.status === 200) {
          const folderLists: folderLists = yield select(folderListsSelector);
          const updateFolderList = (folderList: folderItem[]) => {
            return folderList?.filter(
              (item: folderItem) => item._id !== folderItem._id
            );
          };
          const updateUserFolders = updateFolderList(folderLists.userFolders);
          const updateTeamFolders = updateFolderList(folderLists.teamFolders);
          const updatedFolderLists = {
            userFolders: updateUserFolders,
            teamFolders: updateTeamFolders,
          };
          yield put(MetaDataReducerAction.setFolderItems(updatedFolderLists));
          yield put(MetaDataReducerAction.setFolderItem(null));
          yield put(MetaDataReducerAction.setDeleteFolderLoading(false));
          action.payload.closeAnchorElAfterDelete();
          notify({
            message: response?.data?.message,
            type: ToastTypes.SUCCESS,
          });
        }
      } catch (error) {
        yield put(MetaDataReducerAction.setDeleteFolderLoading(false));
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *updateBoard(action: {
      payload: {
        boardId: string;
        name: string;
        resetState: Function;
        folderId: string;
      };
      type: string;
    }) {
      try {
        const response: AxiosResponse = yield call(
          UpdateBoardService,
          action.payload
        );
        if (response?.status === 200) {
          const folderItem: folderItem = yield select(folderItemSelector);
          const updatedFolderItem = folderItem?.boards.map(
            (board: BoardItem) => {
              if (board._id === action.payload.boardId) {
                return {
                  ...board,
                  name: action.payload.name,
                };
              } else {
                return board;
              }
            }
          );

          const folderList: folderLists = yield select(folderListsSelector);

          const updateFolderBoards = (folders: folderItem[]) => {
            return folders.map((item: folderItem) => {
              if (item._id === action.payload.folderId) {
                const updatedBoards = item.boards.map((board: BoardItem) => {
                  if (board._id === action.payload.boardId) {
                    return {
                      ...board,
                      name: action.payload.name,
                    } as BoardItem;
                  } else {
                    return board;
                  }
                });
                return {
                  ...item,
                  boards: updatedBoards,
                } as folderItem;
              } else {
                return item;
              }
            });
          };

          const updateUserFolders = updateFolderBoards(folderList.userFolders);
          const updateTeamFolders = updateFolderBoards(folderList.teamFolders);

          const folderLists = {
            userFolders: updateUserFolders,
            teamFolders: updateTeamFolders,
          };

          yield put(
            MetaDataReducerAction.setUpdatedFolderBoardItems({
              updatedFolderItem: {
                ...folderItem,
                boards: updatedFolderItem,
              },
              updatedFolderLists: folderLists,
            })
          );
          action.payload.resetState();
          notify({
            message: response?.data?.message,
            type: ToastTypes.SUCCESS,
          });
        }
      } catch (error) {
        action.payload.resetState();
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *deleteBoard(action: {
      payload: {
        boardId: string;
        folderId: string;
        resetState: Function;
      };
      type: string;
    }) {
      try {
        const response: AxiosResponse = yield call(
          DeleteBoardService,
          action.payload.boardId
        );
        if (response?.status === 200) {
          const folderItem: folderItem = yield select(folderItemSelector);
          const updatedFolderBoardItem = folderItem?.boards.filter(
            (board: BoardItem) => board._id !== action.payload.boardId
          );
          const updatedFolderItem = {
            ...folderItem,
            boards: updatedFolderBoardItem,
          };

          const folderLists: folderLists = yield select(folderListsSelector);

          const updateFolderBoards = (folders: folderItem[]) => {
            return folders.map((item: folderItem) => {
              if (item._id === action.payload.folderId) {
                const updatedBoards = item.boards.filter(
                  (board: BoardItem) => board._id !== action.payload.boardId
                );
                return {
                  ...item,
                  boards: updatedBoards,
                } as folderItem;
              } else {
                return item;
              }
            });
          };

          const updateUserFolders = updateFolderBoards(folderLists.userFolders);
          const updateTeamFolders = updateFolderBoards(folderLists.teamFolders);

          const updatedFolderLists = {
            userFolders: updateUserFolders,
            teamFolders: updateTeamFolders,
          };

          yield put(
            MetaDataReducerAction.setUpdatedFolderBoardItems({
              updatedFolderItem: updatedFolderItem,
              updatedFolderLists: updatedFolderLists,
            })
          );
          action.payload.resetState();
          notify({
            message: response?.data?.message,
            type: ToastTypes.SUCCESS,
          });
        }
      } catch (error) {
        action.payload.resetState();
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *createTeam(action: {
      payload: {
        name: string;
        logo: File;
        handleCloseModal: Function;
      };
      type: string;
    }) {
      try {
        yield put(MetaDataReducerAction.setCreateTeamLoading(true));
        const response: AxiosResponse = yield call(CreateTeamService, {
          name: action.payload.name,
          logo: action.payload.logo,
        });
        if (response?.status === 200) {
          yield put(MetaDataReducerAction.setTeamDetail([response?.data]));
          yield put(
            AccountReducerAction.setUserCurrentTeam(response?.data?._id)
          );
          yield put(
            AccountReducerAction.setUserTeams({
              _id: response?.data?._id,
              name: response?.data?.name,
            })
          );
          yield put(MetaDataReducerAction.setCreateTeamLoading(false));
          action.payload.handleCloseModal();
          notify({
            message: response?.data?.message,
            type: ToastTypes.SUCCESS,
          });
        } else {
          yield put(MetaDataReducerAction.setCreateTeamLoading(false));
        }
      } catch (error) {
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *teamDetails() {
      try {
        const response: AxiosResponse = yield call(TeamDetailsService);
        if (response?.status === 200) {
          yield put(MetaDataReducerAction.setTeamDetail(response?.data?.team));
        }
      } catch (error) {
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *sendInvitation(action: {
      payload: {
        teamId: string;
        email: string;
        handleResetForm: Function;
      };
      type: string;
    }) {
      try {
        const { teamId, email, handleResetForm } = action.payload;
        yield put(MetaDataReducerAction.setInvitationLoader(true));
        const response: AxiosResponse = yield call(SendInvitationService, {
          teamId,
          email,
        });
        if (response?.status === 200) {
          yield put(
            MetaDataReducerAction.setTeamDetail([response?.data?.data])
          );
          handleResetForm();
          yield put(MetaDataReducerAction.setInvitationLoader(false));
          notify({
            message: response?.data?.message,
            type: ToastTypes.SUCCESS,
          });
        } else {
          yield put(MetaDataReducerAction.setInvitationLoader(false));
        }
      } catch (error) {
        yield put(MetaDataReducerAction.setInvitationLoader(false));
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *deleteMember(action: {
      payload: {
        email: string;
        handleResetForm: Function;
      };
      type: string;
    }) {
      try {
        const { email, handleResetForm } = action.payload;
        yield put(MetaDataReducerAction.setRemoveMemberLoader(true));
        const response: AxiosResponse = yield call(DeleteMember, {
          email,
        });
        if (response?.status === 200) {
          const teamResponse: AxiosResponse = yield call(TeamDetailsService);
          if (teamResponse?.status === 200) {
            yield put(
              MetaDataReducerAction.setTeamDetail(teamResponse?.data?.team)
            );
          }
          handleResetForm();
          yield put(MetaDataReducerAction.setRemoveMemberLoader(false));
          notify({
            message: response?.data?.message,
            type: ToastTypes.SUCCESS,
          });
        } else {
          yield put(MetaDataReducerAction.setRemoveMemberLoader(false));
        }
      } catch (error) {
        yield put(MetaDataReducerAction.setRemoveMemberLoader(false));
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *notificationList() {
      try {
        yield put(MetaDataReducerAction.setNotificationLoader(true));
        const response: AxiosResponse = yield call(NotificationList);
        if (response?.status === 200) {
          yield put(
            MetaDataReducerAction.setNotificationList(response?.data?.data)
          );
          yield put(MetaDataReducerAction.setNotificationLoader(false));
        }
      } catch (error) {
        yield put(MetaDataReducerAction.setNotificationLoader(false));
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *checkNotification() {
      try {
        const response: AxiosResponse = yield call(CheckNotification);
        if (response?.status === 200) {
          yield put(
            MetaDataReducerAction.setCheckNotificationStatus(response?.data)
          );
        }
      } catch (error) {
        throw new Error();
      }
    },
    *joinTeam(action: {
      payload: {
        notificationId: string;
        teamId: string;
        status: boolean;
      };
      team: string;
    }) {
      try {
        const { notificationId, teamId, status } = action.payload;
        if (status) {
          yield put(MetaDataReducerAction.setAcceptJoinTeamLoader(true));
        } else {
          yield put(MetaDataReducerAction.setRejectJoinTeamLoader(true));
        }
        const response: AxiosResponse = yield call(JoinTeamService, {
          notificationId,
          teamId,
          status,
        });
        if (response?.status === 200) {
          const notificationList: notificationType[] = yield select(
            notificationListSelector
          );
          const updateNotificationItem = notificationList.map(
            (notification: notificationType) => {
              if (notification._id === notificationId) {
                return {
                  ...notification,
                  title: response?.data?.data?.title,
                  description: response?.data?.data?.description,
                  type: response?.data?.data?.type,
                };
              } else {
                return notification;
              }
            }
          );
          const reFetchTeamDetails: AxiosResponse = yield call(
            TeamDetailsService
          );
          if (reFetchTeamDetails?.status === 200) {
            yield put(
              MetaDataReducerAction.setTeamDetail(
                reFetchTeamDetails?.data?.team
              )
            );
          }
          yield put(
            MetaDataReducerAction.setNotificationList(updateNotificationItem)
          );
          if (status) {
            yield put(MetaDataReducerAction.setAcceptJoinTeamLoader(false));
          } else {
            yield put(MetaDataReducerAction.setRejectJoinTeamLoader(false));
          }
        }
      } catch (error) {
        yield put(MetaDataReducerAction.setAcceptJoinTeamLoader(false));
        yield put(MetaDataReducerAction.setRejectJoinTeamLoader(false));
        Sentry.captureException(error);
        throw new Error();
      } finally {
        yield put(MetaDataReducerAction.setAcceptJoinTeamLoader(false));
        yield put(MetaDataReducerAction.setRejectJoinTeamLoader(false));
      }
    },
    *generateTranscript(action: {
      payload: {
        adId: string;
        handleLoadingState: Function;
      };
      type: string;
    }) {
      try {
        const { adId } = action.payload;
        const response: AxiosResponse = yield call(
          GenerateTranscriptService,
          adId
        );
        if (response?.status === 200) {
          const getSwipeFileItems: swipeFileItemListsType = yield select(
            swipeFileListSelector
          );
          const getDiscoveryItems: discoveryItemListsType = yield select(
            discoveryItemsSelector
          );

          const updateTranscriptObject = (
            fileList: (SwipeFileListItems | DiscoveryItem)[]
          ) => {
            return fileList.map((item: SwipeFileListItems | DiscoveryItem) => {
              if (item._id.toString() === adId.toString()) {
                return {
                  ...item,
                  transcript: response?.data?.data,
                };
              } else {
                return item;
              }
            });
          };

          const updatedSwipeFileItems = updateTranscriptObject(
            getSwipeFileItems.data
          );
          const updatedDiscoveryItems = updateTranscriptObject(
            getDiscoveryItems.data
          );

          yield put(
            SwipeFileReducerAction.setSwipeFileItemsData(updatedSwipeFileItems)
          );
          yield put(
            DiscoveryReducerAction.setDiscoveryItemData(updatedDiscoveryItems)
          );

          action.payload.handleLoadingState();
        }
      } catch (error) {
        action.payload.handleLoadingState();
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *changeTeam(action: { payload: { teamId: string } }, type: string) {
      try {
        const response: AxiosResponse = yield call(
          ChangeTeam,
          action.payload.teamId
        );
        if (response?.status === 200) {
          const teamResponse: AxiosResponse = yield call(TeamDetailsService);
          if (teamResponse?.status === 200) {
            yield put(
              MetaDataReducerAction.setTeamDetail(teamResponse?.data?.team)
            );
            yield put(
              AccountReducerAction.setUserCurrentTeam(action.payload.teamId)
            );

            const teamFolderResponse: AxiosResponse = yield call(
              FetchFolderListService
            );
            if (teamFolderResponse?.status === 200) {
              yield put(
                MetaDataReducerAction.setFolderItems(teamFolderResponse?.data)
              );
            }
          }
        }
      } catch (error) {
        Sentry.captureException(error);
        throw new Error();
      }
    },
  },
  sagaType: "takeEvery",
});

export const {
  actions: MetaDataSagaActions,
  saga: MetaDataSaga,
  name: MetaDataSagaName,
} = saga;
