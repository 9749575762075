import LandingImage from "../../../assets/images/landing-image.png";
import {
  Container,
  ContentImage,
  HeroImage,
  MainContent,
  HeroContent,
  HighlightedText,
  HeroTitle,
  CtaButton,
} from "./style";
import routeConstant from "../../../utils/routeConstant";
const HeroSection = () => {
  return (
    <Container>
      <HeroContent>
        <MainContent>
          <HeroTitle>
            The Ultimate Platform for Marketing Agencies and{" "}
            <HighlightedText>AD WorkFlow</HighlightedText>
          </HeroTitle>
        </MainContent>
        <CtaButton to={`/${routeConstant.signup}`}>Get Started</CtaButton>
      </HeroContent>
      <HeroImage>
        <ContentImage src={LandingImage} alt="Platform illustration" />
      </HeroImage>
    </Container>
  );
};

export default HeroSection;
