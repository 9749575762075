import {
  BrandLogo,
  Container,
  FooterNavigation,
  LeftNavigation,
  LeftNavMenuItem,
  LeftNavMenuSection,
  Navigation,
  NavMenuItem,
  NavMenuSection,
} from "./style";
import Logo from "../../../assets/images/Logo.png";
import { CopyRightIcon } from "../../../assets/Icons";
import routeConstant from "../../../utils/routeConstant";

const Footer = () => {
  return (
    <Container>
      <FooterNavigation>
        <BrandLogo src={Logo} alt="brand-logo" />
        <Navigation>
          <NavMenuSection>
            <NavMenuItem to={routeConstant.swipeFiles}>Swipe Files</NavMenuItem>
            <NavMenuItem to={`${routeConstant.discovery}#discovery`}>
              Explore Lounge
            </NavMenuItem>
            <NavMenuItem to={"/"}>Dashboard</NavMenuItem>
          </NavMenuSection>
        </Navigation>
      </FooterNavigation>
      <LeftNavigation>
        <LeftNavMenuSection>
          <LeftNavMenuItem to={routeConstant.termsConditions}>
            Privacy Policy
          </LeftNavMenuItem>
          <LeftNavMenuItem to={routeConstant.termsConditions}>
            Terms & Conditions
          </LeftNavMenuItem>
          <LeftNavMenuItem>
            <CopyRightIcon /> {new Date().getFullYear()} All Rights Reserved
          </LeftNavMenuItem>
        </LeftNavMenuSection>
      </LeftNavigation>
    </Container>
  );
};

export default Footer;
