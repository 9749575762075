import React, { useEffect } from "react";
import { Box, CircularProgress, DialogTitle, Stack } from "@mui/material";
import routeConstant from "../../utils/routeConstant";
import { StyledDivider } from "../../globalStyle";
import { useNavigate } from "react-router-dom";
import { CloseIcon } from "../../assets/Icons";
import {
  CloseIconWrapper,
  FontGrayNormal,
  ModalTitle,
  StyledDialog,
} from "../modals/style";

import { IconWrapper, PaymentButton } from "./style";
import { PaymentSagaActions } from "../../store/payment/sagas";
import { useDispatch, useSelector } from "react-redux";
import {
  isLoadingSelector,
  paymentMethodsListSelector,
} from "../../store/payment/selector";
import { paymentIcons } from "../../utils/constants";
import { paymentMethod } from "../../store/payment/types";

const PaymentTypeModal = ({ subscriptionId, isOpen, setIsOpen }: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector(isLoadingSelector);
  const paymentMethodsList = useSelector(paymentMethodsListSelector);

  useEffect(() => {
    if (subscriptionId && isOpen)
      dispatch(PaymentSagaActions.GetPaymentMethods());
  }, [dispatch, subscriptionId, isOpen]);

  const paymentMethods = paymentMethodsList
    ?.filter(
      (method: paymentMethod) =>
        !(
          method?.type?.includes("googlepay") ||
          method?.type?.includes("applepay") ||
          method?.type?.includes("paysafecard")
        )
    )
    ?.map((method: paymentMethod) => ({
      ...method,
      type: method?.name?.includes("Cards") ? "card" : method?.type,
      icon: paymentIcons.find((icons) => icons?.id?.includes(method?.type))
        ?.icon,
    }));

  return (
    <StyledDialog
      open={isOpen}
      sx={{ scrollbarWidth: "none", "&MuiPaper-root": { width: "50%" } }}
      onClose={() => setIsOpen(false)}
      maxWidth="xl"
    >
      <DialogTitle
        sx={{
          padding: "1.875rem 1.875rem 1.25rem 1.875rem",
        }}
      >
        <Stack direction="column" gap="0.75rem" alignItems="start">
          <ModalTitle>Recommended Payment Options</ModalTitle>
          <FontGrayNormal>
            Please select payment method to proceed further.
          </FontGrayNormal>
        </Stack>
        <CloseIconWrapper aria-label="close" onClick={() => setIsOpen(false)}>
          <CloseIcon />
        </CloseIconWrapper>
      </DialogTitle>
      <StyledDivider />
      <Box
        sx={{
          paddingY: "30px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Box paddingX="40px">
            <ul
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "30px",
              }}
            >
              {paymentMethods.map((method: paymentMethod) => (
                <PaymentButton
                  key={method.type}
                  disabled={method?.name?.includes("Klarna")}
                  onClick={() => {
                    navigate(
                      `${routeConstant?.paymentCheckout}/${method.type}/${subscriptionId}`
                    );
                  }}
                >
                  {method?.icon && (
                    <IconWrapper src={method?.icon} alt={method.name} />
                  )}
                  <div>{method.name}</div>
                </PaymentButton>
              ))}
            </ul>
          </Box>
        )}
      </Box>
    </StyledDialog>
  );
};

export default PaymentTypeModal;
