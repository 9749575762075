import axiosClient from "../apiClient";

export const TermsAndPolicyService = async (documentType: string) => {
  try {
    const response = await axiosClient.get(
      `/document?documentType=${documentType}`
    );
    return response;
  } catch (error) {
    return error;
  }
};
