import SelectDropdown from "../../selectDropdown";
import { BoardIcon } from "../../../assets/Icons";
import {
  Box,
  Chip,
  CircularProgress,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import { StyledChip, StyledMenuItem } from "../../../globalStyle";
import FilterSearchbox from "../filterSearchbox";
import { SelectBoardDropdownProps } from "../../../utils/propTypes";
import { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  boardItemsSelector,
  isLoadingSelector,
  openBoardDropdownSelector,
} from "../../../store/metadata/selector";
import { BoardItem } from "../../../store/metadata/types";
import { ButtonText, HeaderButton2 } from "../../../pageComponents/style";
import { MetaDataSagaActions } from "../../../store/metadata/sagas";
import { MetaDataReducerAction } from "../../../store/metadata/slice";

const SelectBoardDropdown = ({
  showSelectedItemCount,
  selectedBoards,
  handleUpdateBoard,
  selectedBoardIds = [],
  isDisabled,
}: SelectBoardDropdownProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [boards, setBoards] = useState<string[]>([]);
  const [boardSearchText, setBoardSearchText] = useState("");

  const boardItems = useSelector(boardItemsSelector);
  const loading = useSelector(isLoadingSelector);
  const openBoardDropdown = useSelector(openBoardDropdownSelector);

  const handleBoardSearchOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setBoardSearchText(event.target.value);
    if (event.target.value === "") {
      dispatch(
        MetaDataSagaActions.fetchBoardList({ search: event.target.value })
      );
    }
  };

  const handleBoardSearch = () => {
    dispatch(MetaDataSagaActions.fetchBoardList({ search: boardSearchText }));
  };

  const handleBoardSearchKeyDown: React.KeyboardEventHandler<
    HTMLInputElement
  > = (event) => {
    event.stopPropagation();
    if (event.key === "Enter") handleBoardSearch();
  };

  const handleDropDownClose = () => {
    if (boardSearchText) {
      dispatch(MetaDataSagaActions.fetchBoardList({ search: "" }));
      setBoardSearchText("");
    }
    dispatch(MetaDataReducerAction.setOpenBoardDropdown(""));
  };

  useEffect(() => {
    selectedBoards?.length > 0 &&
      setBoards(selectedBoards?.map((board) => board._id));
  }, [selectedBoards]);

  return (
    <SelectDropdown
      openDropdown={openBoardDropdown}
      startAdornmentIcon={<BoardIcon fill={theme.colors.gray1} />}
      multiple={true}
      renderValue={(selected: string[]) => {
        return (
          <Stack
            direction="row"
            justifyContent={
              showSelectedItemCount === 1 ? "space-between" : "start"
            }
          >
            {boards.slice(0, showSelectedItemCount).map((item: string) => {
              let findBoard;
              findBoard = selectedBoards?.find(
                (ele: BoardItem) => ele._id === item
              );
              if (!findBoard) {
                findBoard = boardItems?.find(
                  (ele: BoardItem) => ele._id === item
                );
              }
              return (
                findBoard && <Chip key={findBoard._id} label={findBoard.name} />
              );
            })}
            {boards?.length > showSelectedItemCount && (
              <StyledChip label={`+${boards.length - showSelectedItemCount}`} />
            )}
          </Stack>
        );
      }}
      handleChange={(event: SelectChangeEvent) => {
        const selectedValue = event.target.value;
        if (typeof selectedValue === "string") {
          setBoards(selectedValue.split(","));
        } else {
          const parsedValues = (selectedValue as string[])?.filter(
            (item) => item !== undefined
          );
          setBoards(parsedValues);
        }
      }}
      value={boards ?? []}
      handleClose={handleDropDownClose}
      height="20rem"
    >
      <Box
        sx={{
          margin: "0.938rem",
          position: "sticky",
          top: 0,
          left: 0,
          background: "white",
          borderBottom: `1px solid ${theme.colors.border1}`,
          zIndex: 1,
        }}
        onKeyDown={(event) => event.stopPropagation()}
      >
        <FilterSearchbox
          placeholder="Search"
          handleChange={handleBoardSearchOnChange}
          handleKeyDown={handleBoardSearchKeyDown}
          handleSearchIconClick={handleBoardSearch}
        />
      </Box>
      {boardItems?.map((board: BoardItem, index: number) => (
        <StyledMenuItem key={index} value={board._id}>
          {board.name}
        </StyledMenuItem>
      ))}
      <Box
        sx={{
          position: "sticky",
          bottom: 0,
          background: "white",
          zIndex: 1,
          padding: "0.625rem",
          borderTop: `1px solid ${theme.colors.border1}`,
          textAlign: "right",
        }}
      >
        <HeaderButton2
          variant="contained"
          size="small"
          onClick={() => {
            handleUpdateBoard(
              boards,
              selectedBoardIds?.filter((id) => !boards.includes(id))
            );
          }}
          disabled={isDisabled && !boards?.length}
          startIcon={loading && <CircularProgress size={20} color="inherit" />}
        >
          <ButtonText>Update</ButtonText>
        </HeaderButton2>
      </Box>
    </SelectDropdown>
  );
};

export default SelectBoardDropdown;
