import { createSlice } from "@reduxjs/toolkit";

export const initialState: { termsAndPolicyText: string } = {
  termsAndPolicyText: "",
};

const termsAndPolicy = createSlice({
  name: "termsAndPolicyState",
  initialState,
  reducers: {
    setTermsAndPolicyText(state, action) {
      state.termsAndPolicyText = action.payload;
    },
  },
});

export const {
  actions: TermsAndPolicyReducerAction,
  name: TermsAndPolicyReducerName,
  reducer: TermsAndPolicyReducer,
} = termsAndPolicy;
