import {
  AdContent,
  AdDescription,
  AdTitle,
  Container,
  ContentImage,
  SwipeAdImage,
} from "./style";
import AdImage from "../../../assets/images/landing-image.png";

const SwipeAdSection = () => {
  return (
    <Container id="services">
      <AdContent>
        <AdTitle>Swipe Ads from Meta - FB & Instagram</AdTitle>
        <AdDescription>
          Easily save ads from Meta with a single click. From Facebook to
          Instagram, keep all your creative inspiration in one place—quick,
          simple, and always accessible.
        </AdDescription>
      </AdContent>
      <SwipeAdImage>
        <ContentImage src={AdImage} alt="Ad image" />
      </SwipeAdImage>
    </Container>
  );
};

export default SwipeAdSection;
