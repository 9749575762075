import styled from "styled-components";
import { CustomButton } from "../../../globalStyle";
import { NavLink } from "react-router-dom";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 3.813rem 0;
`;

export const Navigation = styled.div`
  display: flex;
  align-items: center;
  gap: 2.857rem;
`;

export const BrandLogo = styled.img`
  display: flex !important;
  justify-content: start !important;
  width: 16.571rem;
  height: 2.286rem;
  @media only screen and (max-width: 1440px) {
    width: 12rem;
    height: 1.657rem;
  }
`;

export const ModalLogo = styled.img`
  display: flex !important;
  justify-content: center !important;
  width: 16.571rem;
  height: 2.286rem;
  @media only screen and (max-width: 1440px) {
    width: 12rem;
    height: 1.657rem;
  }
`;

export const NavMenuSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3.571rem;
`;

export const NavMenuItem = styled(NavLink)<{
  to?: string;
}>`
  && {
    font-size: 1.143rem;
    font-weight: 400;
    line-height: 1.393rem;
    letter-spacing: 0.036rem;
    color: ${({ theme }) => `${theme.colors.white}`};
    text-decoration: none;
  }
`;

export const NavActions = styled.div`
  display: flex;
  align-items: center;
  gap: 1.071rem;
`;

export const NavAnchorLink = styled.a<{
  href?: string;
}>`
  font-size: 1.143rem;
  font-weight: 400;
  line-height: 1.393rem;
  letter-spacing: 0.036rem;
  color: ${({ theme }) => `${theme.colors.white}`};
  text-decoration: none;
`;

export const PrimaryButton = styled(CustomButton)`
  border-radius: 1.786rem !important;
  font-size: 1.143rem !important;
  color: ${({ theme }) => theme.colors.black4} !important;
  line-height: normal !important;
  background: ${({ theme }) => theme.colors.white} !important;
  && {
    padding: 0.929rem 1.429rem;
  }
`;
