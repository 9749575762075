import { call, put } from "redux-saga/effects";
import { createSliceSaga } from "../saga-helper";
import * as Sentry from "@sentry/react";
import { AxiosResponse } from "axios";
import { TermsAndPolicyReducerAction } from "./slice";
import { TermsAndPolicyService } from "../../services/TermsAndPolicyServices";

const saga = createSliceSaga({
  name: "terms-policy-state",
  caseSagas: {
    *getTermsAndPolicyText(action: {
      payload: {
        documentType: string;
      };
    }) {
      try {
        const response: AxiosResponse = yield call(
          TermsAndPolicyService,
          action.payload.documentType
        );

        if (response?.status === 200) {
          yield put(
            TermsAndPolicyReducerAction.setTermsAndPolicyText(response?.data)
          );
        }
      } catch (error) {
        Sentry.captureException(error);
        throw new Error();
      }
    },
  },
  sagaType: "takeEvery",
});

export const {
  actions: TermsAndPolicySagaActions,
  saga: TermsAndPolicySaga,
  name: TermsAndPolicyName,
} = saga;
