import { Route, Routes } from "react-router-dom";
import "./App.css";
// Components
import Login from "./pageComponents/auth/login";
import Discovery from "./pageComponents/discovery";
import SwipeFiles from "./pageComponents/swipeFiles";
import SignupForm from "./pageComponents/auth/signup";
import MainLayout from "./components/mainLayout";
import GuestGuard from "./routes/GuestGuard";
import Dashboard from "./pageComponents/dashboard";
import ErrorPage from "./pageComponents/errorPage";
import routeConstant from "./utils/routeConstant";
import { CarouselProvider } from "./context/CarouselContext";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import {
  DiscoveryReducer,
  DiscoveryReducerName,
} from "./store/discovery/slice";
import { DiscoverySaga, DiscoverySagaName } from "./store/discovery/sagas";
import { MetaDataReducer, MetaDataReducerName } from "./store/metadata/slice";
import { MetaDataSaga, MetaDataSagaName } from "./store/metadata/sagas";
import Settings from "./pageComponents/settings";
import { AccountReducer, AccountReducerName } from "./store/account/slice";
import { AccountSaga, AccountSagaName } from "./store/account/sagas";
import {
  SwipeFileReducer,
  SwipeFileReducerName,
} from "./store/swipeFile/slice";
import { SwipeFileSaga, SwipeFileSagaName } from "./store/swipeFile/sagas";
import Starred from "./pageComponents/starred";
import { TagsSaga, TagsSagaName } from "./store/tags/sagas";
import { TagsReducer, TagsReducerName } from "./store/tags/slice";
import ForgotPassword from "./pageComponents/auth/forgotPassword";
import ResetPassword from "./pageComponents/auth/resetPassword";
import Notification from "./pageComponents/notification";
import Subscription from "./pageComponents/subscription";
import PaymentHistoryPage from "./pageComponents/paymentHistory";
import AdDetails from "./components/commonComponents/adDetails";
import MyFolder from "./pageComponents/myfolder";
import SharedFolders from "./pageComponents/sharedFolders";
import LandingPage from "./pageComponents/landingPage";
import AccessControlRoute from "./routes/AccessControlRoute";
import ProcessingPage from "./components/payment/ProcessingPage";
import PreviewPage from "./components/payment/PreviewPage";
import { PaymentCheckout } from "./components/payment/PaymentCheckout";
import { StatusContainer } from "./components/payment/StatusContainer";
import { PaymentReducer, PaymentReducerName } from "./store/payment/slice";
import { PaymentSaga, PaymentSagaName } from "./store/payment/sagas";
import { RedirectContainer } from "./components/payment/RedirectContainer";
import {
  TermsAndPolicyReducer,
  TermsAndPolicyReducerName,
} from "./store/termsAndPolicy/slice";
import {
  TermsAndPolicyName,
  TermsAndPolicySaga,
} from "./store/termsAndPolicy/saga";
import TermsAndPolicy from "./pageComponents/termsAndPolicy";
import VerifyEmail from "./pageComponents/auth/verifyEmail";
import UserVerification from "./pageComponents/auth/userVerification";

const App = () => {
  useInjectReducer({
    reducer: DiscoveryReducer,
    key: DiscoveryReducerName,
  });

  useInjectReducer({
    reducer: MetaDataReducer,
    key: MetaDataReducerName,
  });

  useInjectReducer({
    reducer: AccountReducer,
    key: AccountReducerName,
  });

  useInjectReducer({
    reducer: SwipeFileReducer,
    key: SwipeFileReducerName,
  });

  useInjectReducer({
    reducer: TagsReducer,
    key: TagsReducerName,
  });

  useInjectReducer({
    reducer: PaymentReducer,
    key: PaymentReducerName,
  });

  useInjectReducer({
    reducer: TermsAndPolicyReducer,
    key: TermsAndPolicyReducerName,
  });

  useInjectSaga({
    saga: DiscoverySaga,
    key: DiscoverySagaName,
  });

  useInjectSaga({
    saga: MetaDataSaga,
    key: MetaDataSagaName,
  });
  useInjectSaga({
    saga: AccountSaga,
    key: AccountSagaName,
  });

  useInjectSaga({
    saga: SwipeFileSaga,
    key: SwipeFileSagaName,
  });

  useInjectSaga({
    saga: TagsSaga,
    key: TagsSagaName,
  });

  useInjectSaga({
    saga: PaymentSaga,
    key: PaymentSagaName,
  });

  useInjectSaga({
    saga: TermsAndPolicySaga,
    key: TermsAndPolicyName,
  });

  return (
    <Routes>
      {/* Public Routes */}
      <Route
        path={routeConstant?.landingPage}
        element={
          <CarouselProvider>
            <LandingPage />
          </CarouselProvider>
        }
      />
      <Route
        path={routeConstant?.login}
        element={
          <AccessControlRoute>
            <CarouselProvider>
              <Login />
            </CarouselProvider>
          </AccessControlRoute>
        }
      />
      <Route
        path={routeConstant?.signup}
        element={
          <CarouselProvider>
            <SignupForm />
          </CarouselProvider>
        }
      />
      <Route
        path={routeConstant?.forgotPassword}
        element={
          <CarouselProvider>
            <ForgotPassword />
          </CarouselProvider>
        }
      />
      <Route
        path={`${routeConstant?.verifyEmail}/:token`}
        element={
          <CarouselProvider>
            <VerifyEmail />
          </CarouselProvider>
        }
      />
      <Route
        path={`${routeConstant?.verifyEmail}`}
        element={
          <AccessControlRoute>
            <CarouselProvider>
              <UserVerification />
            </CarouselProvider>
          </AccessControlRoute>
        }
      />
      <Route
        path={routeConstant?.termsConditions}
        element={<TermsAndPolicy />}
      />

      <Route path={routeConstant?.privacyPolicy} element={<TermsAndPolicy />} />

      <Route
        path={`${routeConstant?.resetPassword}/:token`}
        element={
          <CarouselProvider>
            <ResetPassword />
          </CarouselProvider>
        }
      />

      <Route
        path={`${routeConstant?.subscription}`}
        element={<Subscription />}
      />
      <Route
        path={`${routeConstant?.paymentPreview}/:subscriptionId`}
        element={<PreviewPage />}
      />
      <Route
        path={`${routeConstant?.paymentCheckout}/:type/:subscriptionId`}
        element={<PaymentCheckout />}
      />
      <Route
        path={routeConstant?.paymentProcessing}
        element={<ProcessingPage />}
      />
      <Route
        path={`${routeConstant?.paymentStatus}/:type`}
        element={<StatusContainer />}
      />
      <Route path={routeConstant?.redirect} element={<RedirectContainer />} />

      <Route path="/" element={<MainLayout />}>
        {/* Protected Routes */}
        <Route element={<GuestGuard />}>
          <Route path={routeConstant?.dashboard} element={<Dashboard />} />
          <Route path={routeConstant?.discovery} element={<Discovery />} />
          <Route path={routeConstant?.swipeFiles} element={<SwipeFiles />} />
          <Route
            path={`/:categoryType${routeConstant?.adDetails}/:libraryId`}
            element={<AdDetails />}
          />
          <Route
            path={`${routeConstant.myFolders}/:folderId`}
            element={<MyFolder />}
          />
          <Route
            path={`${routeConstant.sharedFolders}/:folderId`}
            element={<SharedFolders />}
          />
          <Route path={routeConstant?.team} />
          <Route path={routeConstant?.help} />
          <Route path={routeConstant?.settings} element={<Settings />} />
          <Route path={routeConstant?.starred} element={<Starred />} />
          <Route
            path={routeConstant?.notification}
            element={<Notification />}
          />
          <Route
            path={routeConstant?.paymentHistory}
            element={<PaymentHistoryPage />}
          />
        </Route>
        {/* Other Routes */}
      </Route>
      <Route path={routeConstant?.error} element={<ErrorPage />} />
    </Routes>
  );
};

export default App;
