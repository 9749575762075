import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  AccountLoginText,
  AccountText,
  BottomButtonWrapper,
  FormFieldWrapper,
  FormWrapper,
  GoogleIconWrapper,
  LoginLayoutContainer,
  LogoWrapper,
  PageTitle,
  StyledLoginButton,
  TermsAndPolicyText,
  CredentialSection,
  ErrorMessage,
  ImageSection,
  LoginTextBox,
  TermsLink,
} from "../style";
import Logo from "../../../assets/images/Logo.png";
import {
  EyeIcon,
  EyeIconButton,
  StyledCheckbox,
  StyledLabel,
} from "../../../components/commonComponents/formFields/style";
import { signUpValidationSchema } from "../../../utils/formValidationSchema";
import { SignUpFormValuesProp } from "../../../utils/propTypes";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { GoogleIcon, LoginResponseIcon } from "../../../assets/Icons";
import {
  GoogleLoginService,
  SignUpService,
} from "../../../services/AuthServices";
import { useNavigate } from "react-router-dom";
import { CarouselContext } from "../../../context/CarouselContext";
import { AuthContext } from "../../../context/AuthContext";
import { useGoogleLogin } from "@react-oauth/google";
import { AxiosResponse } from "axios";
import {
  GoogleLoginResponse,
  LoginSignupResponse,
} from "../../../types/AuthTypes";
import { AccountReducerAction } from "../../../store/account/slice";
import { useDispatch } from "react-redux";
import { ToastTypes, notify, webkitInputCSSFix } from "../../../utils/helpers";
import { useTheme } from "styled-components";
import axios from "axios";
import { CarouselType } from "../../../types/Carousel";
import routeConstant from "../../../utils/routeConstant";

const SignupForm = () => {
  const { setAuthStatus, isAuthenticated } = useContext(AuthContext);
  const { carouselImages } = useContext(CarouselContext);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [googleLoginLoading, setGoogleLoginLoading] = useState<boolean>(false);
  const [signupErrorMessage, setSignupErrorMessage] = useState<null | string>(
    null
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm<SignUpFormValuesProp>({
    resolver: yupResolver(signUpValidationSchema),
  });
  const onSubmit = async () => {
    setSignupErrorMessage(null);
    const values = getValues();
    values.email = values.email.toLowerCase();
    setLoading(true);
    const response = (await SignUpService(
      values
    )) as AxiosResponse<LoginSignupResponse>;

    if (response?.status === 201) {
      const data = { ...response.data.data, isLoggedIn: true };
      const { token, ...rest } = data;
      setAuthStatus(true);
      dispatch(AccountReducerAction.setUserDetails(rest.user));
      dispatch(
        AccountReducerAction.setUserPhoneNumber(
          data?.user?.phoneNumber ? data?.user?.phoneNumber : ""
        )
      );
      navigate(`/${routeConstant?.login}`);
      notify({
        message: response?.data?.message,
        type: ToastTypes.SUCCESS,
      });
      setLoading(false);
      reset();
    } else {
      setLoading(false);
    }
    if (axios.isAxiosError(response)) {
      setSignupErrorMessage(response.response?.data?.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate(-1);
    }
  }, []);

  const handlePassword = () => {
    setShowPassword(!showPassword);
  };

  const loginWithGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      setSignupErrorMessage(null);
      setGoogleLoginLoading(true);
      const reqParams = {
        code: tokenResponse.access_token,
      };
      const response = (await GoogleLoginService(
        reqParams
      )) as AxiosResponse<GoogleLoginResponse>;
      if (response?.status) {
        const data = { ...response.data.data, isLoggedIn: true };
        setAuthStatus(true);
        localStorage.setItem("User", JSON.stringify(data));
        dispatch(AccountReducerAction.setUserDetails(data.user));
        dispatch(
          AccountReducerAction.setUserPhoneNumber(
            data?.user?.phoneNumber ? data?.user?.phoneNumber : ""
          )
        );
        localStorage.setItem("AccessToken", data.token);
        setGoogleLoginLoading(false);
        navigate(`/${routeConstant?.subscription}`, {
          replace: true,
        });
      }
      if (axios.isAxiosError(response)) {
        setSignupErrorMessage(response.response?.data?.message);
        setGoogleLoginLoading(false);
      }
    },
  });

  const onKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    submitHandler: () => void
  ) => {
    if (e.key === "Enter") {
      e.preventDefault();
      submitHandler();
    }
  };
  const renderCarousel = (images: CarouselType[], direction: "ltr" | "rtl") => (
    <div className={`Marquee-content Marquee-content-${direction}`}>
      {[...Array(6)].flatMap((_, repetitionIndex) =>
        images.map((image, index) => (
          <div className="Marquee-tag" key={`${index}-${repetitionIndex}`}>
            <img src={image.imageUrl} alt="Vertical-Card-1" />
          </div>
        ))
      )}
    </div>
  );
  return (
    <LoginLayoutContainer container>
      <CredentialSection xs={12} md={6}>
        <LogoWrapper src={Logo} alt="app-logo" />
        <PageTitle>Sign Up</PageTitle>
        <AccountText>
          {"Already have an account ? "}
          <AccountLoginText to="/login">Login</AccountLoginText>
        </AccountText>
        {signupErrorMessage && (
          <LoginTextBox style={{ background: theme.colors.error }}>
            <LoginResponseIcon />
            {signupErrorMessage}
          </LoginTextBox>
        )}
        <FormWrapper>
          <FormFieldWrapper>
            <FormControl
              fullWidth
              sx={{
                "& > label": {
                  top: 23,
                  left: 0,
                  color: "white",
                  '&[data-shrink="false"]': {
                    top: 5,
                  },
                },
                "& > div > input": {
                  padding: "1.906rem 0.875rem 0.719rem !important",
                  color: "white",
                  border: "3px solid white",
                  borderRadius: "2px",
                  zIndex: 2,
                },
                "& legend": {
                  display: "none",
                },
                "& fieldset": {
                  top: 0,
                },
              }}
            >
              <InputLabel
                style={{ color: "white" }}
                htmlFor="outlined-adornment-fullname-login"
              >
                Full Name
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-fullname-login"
                type="text"
                {...register("fullName", { required: true })}
                sx={webkitInputCSSFix}
                onKeyDown={(e) => onKeyDown(e, handleSubmit(onSubmit))}
              />
            </FormControl>
            {errors.fullName?.message && (
              <ErrorMessage>{errors.fullName?.message}</ErrorMessage>
            )}

            <FormControl
              fullWidth
              sx={{
                "& > label": {
                  top: 23,
                  left: 0,
                  color: "white",
                  '&[data-shrink="false"]': {
                    top: 5,
                  },
                },
                "& > div > input": {
                  padding: "1.906rem 0.875rem 0.719rem !important",
                  color: "white",
                  border: "3px solid white",
                  borderRadius: "2px",
                  zIndex: 2,
                },
                "& legend": {
                  display: "none",
                },
                "& fieldset": {
                  top: 0,
                },
              }}
            >
              <InputLabel
                style={{ color: "white" }}
                htmlFor="outlined-adornment-email-login"
              >
                Email
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-email-login"
                type="email"
                sx={webkitInputCSSFix}
                {...register("email", { required: true })}
                onKeyDown={(e) => onKeyDown(e, handleSubmit(onSubmit))}
              />
            </FormControl>
            {errors.email?.message && (
              <ErrorMessage>{errors.email?.message}</ErrorMessage>
            )}

            <FormControl
              fullWidth
              sx={{
                "& > label": {
                  top: 23,
                  left: 0,
                  color: "white",
                  '&[data-shrink="false"]': {
                    top: 5,
                  },
                },
                "& > div > input": {
                  padding: "1.906rem 0.875rem 0.719rem !important",
                  color: "white",

                  zIndex: 2,
                },
                "& > div": {
                  border: "3px solid white",
                  borderRadius: "2px",
                },
                "& legend": {
                  display: "none",
                },
                "& fieldset": {
                  top: 0,
                },
              }}
            >
              <InputLabel
                style={{
                  width: "100%",
                  color: "white",
                }}
                htmlFor="outlined-adornment-password-login"
              >
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password-login"
                type={showPassword ? "text" : "password"}
                onKeyDown={(e) => onKeyDown(e, handleSubmit(onSubmit))}
                {...register("password", { required: true })}
                label="Password"
                sx={webkitInputCSSFix}
                endAdornment={
                  <EyeIconButton
                    onClick={(e) => {
                      e.preventDefault();
                      handlePassword();
                    }}
                  >
                    <EyeIcon
                      src={
                        showPassword
                          ? "/assets/images/eye-icon.svg"
                          : "/assets/images/eye-close.svg"
                      }
                      alt="show-hide-password-icon"
                    />
                  </EyeIconButton>
                }
              />
            </FormControl>
            {errors.password?.message && (
              <ErrorMessage>{errors.password?.message}</ErrorMessage>
            )}
          </FormFieldWrapper>
          <TermsAndPolicyText>
            <StyledLabel htmlFor="label">
              <StyledCheckbox size="small" id="label" />
              Agree to The{" "}
              <TermsLink to="/terms-conditions">
                {" "}
                Terms & Conditions{" "}
              </TermsLink>{" "}
              and <TermsLink to="/terms-conditions"> Privacy Policy </TermsLink>
            </StyledLabel>
          </TermsAndPolicyText>

          <BottomButtonWrapper>
            <StyledLoginButton
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              disabled={loading}
              startIcon={
                loading && <CircularProgress size={20} color="inherit" />
              }
            >
              Sign Up
            </StyledLoginButton>
            <StyledLoginButton
              variant="contained"
              withGoogle
              onClick={() => loginWithGoogle()}
              disabled={googleLoginLoading}
            >
              <GoogleIconWrapper>
                <GoogleIconWrapper>
                  {googleLoginLoading ? (
                    <CircularProgress size={22} color="inherit" />
                  ) : (
                    <GoogleIcon />
                  )}
                </GoogleIconWrapper>
              </GoogleIconWrapper>
              Sign Up with Google
            </StyledLoginButton>
          </BottomButtonWrapper>
        </FormWrapper>
      </CredentialSection>
      <ImageSection xs={12} md={6}>
        {carouselImages.carousel_one?.length > 0 && (
          <>
            <div className="Marquee">
              {renderCarousel(carouselImages.carousel_one, "ltr")}
            </div>
            <div className="Marquee">
              {renderCarousel(carouselImages.carousel_two, "rtl")}
            </div>
            <div className="Marquee">
              {renderCarousel(carouselImages.carousel_three, "ltr")}
            </div>
          </>
        )}
      </ImageSection>
    </LoginLayoutContainer>
  );
};

export default SignupForm;
