import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./slice";

const selectDomain = (state: any) => state.paymentState || initialState;

export const paymentSelector = createSelector(selectDomain, (state) => state);

export const paymentHistoryListSelector = createSelector(
  selectDomain,
  (state) => state.paymentHistoryList
);

export const isLoadingSelector = createSelector(
  selectDomain,
  (state) => state.isLoading
);

export const paymentDetailsSelector = createSelector(
  selectDomain,
  (state) => state.paymentDetails
);

export const subscriptionSkipLimitSelector = createSelector(
  selectDomain,
  (state) => state.subscriptionSkipLimit
);

export const paymentMethodsListSelector = createSelector(selectDomain,
  (state) => state.paymentMethods
)
