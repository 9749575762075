import organizationImage from "../../../assets/images/organization.png";
import {
  Container,
  Organization,
  Title,
  SubTitle,
  ContentImage,
} from "./style";
import { Box } from "@mui/material";
const OrganizationSection = () => {
  return (
    <Container>
      <Organization>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Title>Organization at its best</Title>
          <SubTitle>
            Organize ads into folders for campaigns or concepts and share them
            effortlessly with your team. Swipe Lounge makes collaboration
            simple, so your ideas are always aligned.
          </SubTitle>
        </Box>
        <ContentImage src={organizationImage} alt="organization" />
      </Organization>
    </Container>
  );
};

export default OrganizationSection;
