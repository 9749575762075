import { useContext, useState } from "react";
import {
  AccountLoginText,
  AccountText,
  BottomButtonWrapper,
  FormWrapper,
  LoginLayoutContainer,
  LogoWrapper,
  PageTitle,
  StyledLoginButton,
  CredentialSection,
  ImageSection,
} from "../style";
import Logo from "../../../assets/images/Logo.png";
import { CircularProgress } from "@mui/material";
import { useForm } from "react-hook-form";
import { verifyEmailService } from "../../../services/AuthServices";
import { CarouselContext } from "../../../context/CarouselContext";
import axios, { AxiosResponse } from "axios";
import { CarouselType } from "../../../types/Carousel";
import { useNavigate, useParams } from "react-router-dom";
import routeConstant from "../../../utils/routeConstant";
import { notify, ToastTypes } from "../../../utils/helpers";

const VerifyEmail = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { token } = useParams();
  const navigate = useNavigate();
  const { handleSubmit, reset } = useForm({});
  const { carouselImages } = useContext(CarouselContext);

  const onSubmit = async () => {
    setLoading(true);
    const response = (await verifyEmailService(
      token as string
    )) as AxiosResponse<any>;
    if (response.status === 200) {
      reset();
      setLoading(false);
      navigate(`/${routeConstant?.login}`, {
        replace: true,
      });
      notify({
        message: response?.data?.message,
        type: ToastTypes.SUCCESS,
      });
    } else {
      setLoading(false);
    }
    if (axios.isAxiosError(response)) {
      setLoading(false);
    }
  };

  const renderCarousel = (images: CarouselType[], direction: "ltr" | "rtl") => (
    <div className={`Marquee-content Marquee-content-${direction}`}>
      {[...Array(6)].flatMap((_, repetitionIndex) =>
        images.map((image, index) => (
          <div className="Marquee-tag" key={`${index}-${repetitionIndex}`}>
            <img src={image.imageUrl} alt="Vertical-Card-1" />
          </div>
        ))
      )}
    </div>
  );

  return (
    <LoginLayoutContainer container>
      <CredentialSection xs={12} md={6}>
        <LogoWrapper src={Logo} alt="app-logo" />
        <PageTitle
          style={{
            marginTop: "15%",
          }}
        >
          Verify Email
        </PageTitle>
        <AccountText>
          {"Already have a verified account ? "}
          <AccountLoginText to="/login">Login</AccountLoginText>
        </AccountText>

        <FormWrapper>
          <BottomButtonWrapper>
            <StyledLoginButton
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              disabled={loading}
              startIcon={
                loading && <CircularProgress size={20} color="inherit" />
              }
            >
              Verify Email
            </StyledLoginButton>
          </BottomButtonWrapper>
        </FormWrapper>
      </CredentialSection>
      <ImageSection xs={12} md={6}>
        {carouselImages.carousel_one?.length > 0 && (
          <>
            <div className="Marquee">
              {renderCarousel(carouselImages.carousel_one, "ltr")}
            </div>
            <div className="Marquee">
              {renderCarousel(carouselImages.carousel_two, "rtl")}
            </div>
            <div className="Marquee">
              {renderCarousel(carouselImages.carousel_three, "ltr")}
            </div>
          </>
        )}
      </ImageSection>
    </LoginLayoutContainer>
  );
};

export default VerifyEmail;
