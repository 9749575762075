import { createSlice } from "@reduxjs/toolkit";
import { DashoardAnalyticsStateType } from "./types";

export const initialState: DashoardAnalyticsStateType = {
  dashboardAnalytics: {
    userSavedAds: 0,
    totalSavedAds: 0,
    adSavedTodayCount: 0,
    imagePercentage: "",
    videoPercentage: "",
    images: 0,
    videos: 0,
    userActivePlan: {
      _id: "",
      name: "",
      featureList: [],
    },
  },
  dashboardAdAnalytics: [],
  dashboardLoading: false,
  openViewTranscriptModal: false,
  recommendedPlans: [],
  teamMemberSavedAds: [],
};

const dashboardAnalytics = createSlice({
  name: "dashboardAnalyticsState",
  initialState,
  reducers: {
    setDashboardAnalyticsData(state, action) {
      state.dashboardAnalytics = {
        ...action.payload,
        imagePercentage: `${
          action.payload.imagePercentage ? action.payload.imagePercentage : 0
        }%`,
        videoPercentage: `${
          action.payload.videoPercentage ? action.payload.videoPercentage : 0
        }%`,
      };
    },
    setDashboardAdAnalyticsData(state, action) {
      const dashboardAdAnalytics = action.payload.map((item: any) => {
        const ad = item.ad;
        const page = ad.page || {};
        const platforms = ad.platforms || {};
        return {
          _id: item._id,
          name: page.name || "",
          logo: page.logo || "",
          url: page.url || "",
          facebookCount: platforms.facebookCount || 0,
          instagramCount: platforms.instagramCount || 0,
        };
      });
      state.dashboardAdAnalytics = dashboardAdAnalytics;
    },
    setDashboardLoadingState(state, action) {
      state.dashboardLoading = action.payload;
    },
    setOpenViewTranscriptModal(state, action) {
      state.openViewTranscriptModal = action.payload;
    },
    setDashboardRecommendedPlans(state, action) {
      state.recommendedPlans = action.payload?.data?.Plan;
    },
    setTeamMemberSavedAds(state, action) {
      state.teamMemberSavedAds = action.payload;
    },
  },
});

export const {
  actions: DashoardAnalyticsReducerAction,
  name: DashoardAnalyticsReducerName,
  reducer: DashoardAnalyticsReducer,
} = dashboardAnalytics;
