import { useDispatch, useSelector } from "react-redux";
import {
  SettingsSectionWrapper1,
  SettingsSectionWrapper2,
} from "../../pageComponents/settings/style";
import {
  InvoiceButton,
  PlanDescription,
  PlanDetailWrapper,
  PlanDetailsWrapper,
  PlanName,
  PlanPrice,
  UpgradePlanButton,
  Wrapper,
} from "../planDetailsSection/style";
import { useEffect, useState } from "react";
import {
  isLoadingSelector,
  paymentHistoryListSelector,
} from "../../store/payment/selector";
import { PaymentSagaActions } from "../../store/payment/sagas";
import { paymentHistoryListDataType } from "../../store/payment/types";
import { Chip } from "@mui/material";
import InvoiceModal from "./InvoiceModal";

const PaymentHistory = () => {
  const dispatch = useDispatch();

  const [openInvoiceModal, setOpenInvoiceModal] = useState<boolean>(false);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<string>();

  const paymentHistoryList = useSelector(paymentHistoryListSelector);
  const isLoading = useSelector(isLoadingSelector);

  useEffect(() => {
    dispatch(PaymentSagaActions.getPaymentHistoryList());
  }, [dispatch]);

  const handleInvoiceClick = (paymentInvoiceId: string) => {
    setOpenInvoiceModal(true);
    setSelectedInvoiceId(paymentInvoiceId);
  };

  const handleSubscriptionCancel = (id: string) => {
    dispatch(PaymentSagaActions.cancelSubscription(id));
  };
  return (
    <SettingsSectionWrapper1>
      <SettingsSectionWrapper2>
        {paymentHistoryList?.data?.length > 0 &&
          paymentHistoryList?.data?.map(
            (payment: paymentHistoryListDataType, index: number) => (
              <Wrapper key={payment._id}>
                <PlanDetailsWrapper>
                  <PlanDetailWrapper>
                    <Chip
                      label={payment?.planStatus}
                      sx={{
                        padding: "3px 7px",
                        borderRadius: "20px",
                        background: "#FAFAFA",
                        border: "1px solid #EAECEE",
                        color: "#414042",
                        fontSize: "14px",
                        fontWeight: 500,
                      }}
                    />
                    <PlanName>{payment.planName}</PlanName>
                    <PlanDescription>
                      Plan purchased on{" "}
                      {new Date(payment.PlanPurchased).toLocaleDateString() ??
                        ""}
                      .
                    </PlanDescription>
                    <PlanPrice weight={400} size="0.875rem">
                      <PlanPrice weight={700} size="1rem">
                        ${payment.amount}
                      </PlanPrice>{" "}
                      / month
                    </PlanPrice>
                  </PlanDetailWrapper>

                  {payment.isPaymentDone !== null &&
                    (!payment.isPaymentDone ? (
                      <UpgradePlanButton
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSubscriptionCancel(payment._id)}
                      >
                        Cancel Renewal
                      </UpgradePlanButton>
                    ) : (
                      <InvoiceButton
                        style={{ cursor: "pointer" }}
                        onClick={() => handleInvoiceClick(payment._id)}
                      >
                        {/* <DownloadIcon /> */}
                        Invoice
                      </InvoiceButton>
                    ))}
                </PlanDetailsWrapper>
              </Wrapper>
            )
          )}
      </SettingsSectionWrapper2>
      <InvoiceModal
        openInvoiceModal={openInvoiceModal}
        setOpenInvoiceModal={setOpenInvoiceModal}
        selectedInvoiceId={selectedInvoiceId}
      />
    </SettingsSectionWrapper1>
  );
};

export default PaymentHistory;
