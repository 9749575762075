import { useNavigate } from "react-router-dom";
import Logo from "../../../assets/images/Logo.png";
import routeConstant from "../../../utils/routeConstant";
import {
  Container,
  NavActions,
  Navigation,
  PrimaryButton,
  NavMenuItem,
  BrandLogo,
  NavAnchorLink,
} from "./style";

const LandingNavbar = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <Navigation>
        <BrandLogo src={Logo} alt="brand-logo" />
        <NavAnchorLink href="#services">Services</NavAnchorLink>
        <NavAnchorLink href="#about-platform">About Platform</NavAnchorLink>
      </Navigation>
      <NavActions>
        <NavMenuItem to={`/${routeConstant.login}`}>Log in</NavMenuItem>
        <PrimaryButton onClick={() => navigate(`/${routeConstant.signup}`)}>
          Get Started
        </PrimaryButton>
      </NavActions>
    </Container>
  );
};

export default LandingNavbar;
