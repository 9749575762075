import FacebookIcon from "../assets/images/facebook-icon.png";
import InstagramIcon from "../assets/images/instagram-icon.png";
import linkedinIcon from "../assets/images/linkedin-icon.png";
import { BoardIcon, CarouselIcon, ImageIcon, VideoIcon } from "../assets/Icons";
import paymentCard from "../assets/images/paymentcard.svg";
import Klarna from "../assets/images/klarna.svg";
import GooglePay from "../assets/images/googlepay.svg";
import AmazonPay from "../assets/images/amazonpay.svg";
import ApplePay from "../assets/images/applepay.svg";
import paysafeCard from "../assets/images/paysafecard.svg";

export const swipeFilesTabs = [
  {
    id: "my-ads",
    label: "My Ads",
    searchAndFilterButtons: ["filter", "sort", "viewSelection"],
  },
  { id: "brands", label: "Brands", searchAndFilterButtons: ["favourite"] },
];

export const discoveryTabs = [
  {
    id: "discovery",
    label: "Discovery",
    searchAndFilterButtons: ["filter", "sort"],
  },
  {
    id: "community",
    label: "Community",
    searchAndFilterButtons: ["sort"],
  },
  {
    id: "brands",
    label: "Brands",
    searchAndFilterButtons: ["filter", "favourite"],
  },
];

export const adsTableColumns = [
  {
    id: "Ad",
    label: "Ad",
  },
  {
    id: "ativeTime",
    label: "Active Time",
  },
  {
    id: "boards",
    label: "Boards",
  },
  {
    id: "rating",
    label: "Rating",
  },
  {
    id: "tags",
    label: "Tags",
  },
];

export const staticBoards = [
  { id: 1, label: "#carb_cut", icon: BoardIcon },
  { id: 2, label: "#complete_gut", icon: BoardIcon },
  { id: 3, label: "#female_shred", icon: BoardIcon },
  { id: 4, label: "#inno_cleance", icon: BoardIcon },
  { id: 5, label: "#carb_cut", icon: BoardIcon },
  { id: 6, label: "#complete_gut", icon: BoardIcon },
];

export const staticFormats = [
  { _id: 1, name: "Video", logo: VideoIcon },
  { _id: 2, name: "Image", logo: ImageIcon },
  { _id: 3, name: "Carousel", logo: CarouselIcon },
];

export const staticPlatforms = [
  { _id: "facebook", name: "Facebook", logo: FacebookIcon },
  { _id: "instagram", name: "Instagram", logo: InstagramIcon },
  { _id: "linkedin", name: "LinkedIn", logo: linkedinIcon },
];

export const sortingOptions = [
  { value: "Newest", label: "Newest" },
  { value: "Oldest", label: "Oldest" },
  { value: "longestRunning", label: "Longest Running" },
];

export const saveAdModalTabs = [
  { id: "chrome-extension", label: "Chrome Extension" },
  { id: "bulk-upload", label: "Bulk Upload" },
];

export const starredTabs = [
  {
    id: "starred",
    label: "My Starred Ads",
    searchAndFilterButtons: [],
  },
];

export const tutorialDrawerTabs = [
  { id: "swipe-ad", label: "Swipe Ad" },
  { id: "explore-lounge", label: "Explore Lounge" },
];

export const renderNotificationButtonText = "TeamInvite";

export const defaultFolderName = "Default";

export const monthlyPlanType = "Monthly";

export const yearlyPlanType = "Yearly";

export const chromeExtensionUrl =
  "https://chrome.google.com/webstore/detail/baefhnjemognmkegfpiodhfehaojjhbf";

export const paymentType = {
  card: "card",
  klarna: "klarna",
  dropin: "dropin",
  googlepay: "googlepay",
  applepay: "applepay",
  paypal: "paypal",
  amazonpay: "amazonpay",
  ideal: "ideal",
  giropay: "giropay",
  dotpay: "dotpay",
  eps: "eps",
  directEbanking: "directEbanking",
  bcmc: "bcmc",
  paysafecard: "paysafecard",
  klarna_paynow: "klarna_paynow",
  klarna_account: "klarna_account",
};

export const paymentIcons = [
  { id: "scheme", icon: paymentCard },
  { id: "klarna_account", icon: Klarna },
  { id: "paysafecard", icon: paysafeCard },
  { id: "googlepay", icon: GooglePay },
  { id: "amazonpay", icon: AmazonPay },
  { id: "applepay", icon: ApplePay },
];
