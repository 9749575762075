import {
  CheckedBoxIcon,
  CloseSubscriptionIcon,
  NavigateLeftIcon,
  NavigateRightIcon,
  SwipeLoungeIcon,
} from "../../assets/Icons";
import { useNavigate } from "react-router-dom";
import routeConstant from "../../utils/routeConstant";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { CardContainer } from "../../components/dashboardSections/style";
import { useTheme } from "styled-components";
import { CustomButton } from "../../globalStyle";
import {
  CardButtonWrapper,
  CardDetailList,
  CardDetailListText,
  CardDetailListWrapper,
  CardDetailsHeader,
  CardDetailsWrapper,
  CardHeaderSection,
  CardName,
  CardPlanDescription,
  CardPrice,
  HeaderNavigation,
  MainSection,
  MainSectionHeading,
  MainSectionSubHeading,
  MainSectionSubHeadingWrapper,
  MainSectionWrapper,
} from "./style";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  isPlanLoadingSelector,
  planListsSelector,
  selectPlanListSelector,
  selectPlanTypeSelector,
  userDetailsSelector,
} from "../../store/account/selector";
import { PlanType } from "../../store/account/types";
import { AccountSagaActions } from "../../store/account/sagas";
import { AccountReducerAction } from "../../store/account/slice";
import { monthlyPlanType, yearlyPlanType } from "../../utils/constants";

const Subscription = () => {
  // Hooks
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Selectors
  const selectedPlanList = useSelector(selectPlanListSelector);
  const isPlanLoading = useSelector(isPlanLoadingSelector);
  const selectedPlanType = useSelector(selectPlanTypeSelector);
  const planLists = useSelector(planListsSelector);
  const [currentIndex, setCurrentIndex] = useState(0);
  const maxVisiblePlans = 3; // Number of plans to display at once

  // Get the visible plans based on the current index
  const [visiblePlans, setVisiblePlans] = useState([]);

  const userDetails = useSelector(userDetailsSelector);
  const user: any = JSON.parse(localStorage.getItem("User") as string);
  // Handle navigation to the right
  const handleNavigateRight = () => {
    if (currentIndex + maxVisiblePlans < selectedPlanList.length) {
      setCurrentIndex((prevIndex) =>
        Math.min(prevIndex + 1, selectedPlanList.length - maxVisiblePlans)
      );
    }
  };

  // Handle navigation to the left
  const handleNavigateLeft = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    }
  };

  // Functions
  const handleClose = () => {
    navigate(`${routeConstant.dashboard}`, {
      replace: true,
    });
  };

  useEffect(() => {
    selectedPlanList.length > 0 &&
      setVisiblePlans(selectedPlanList.slice(0, maxVisiblePlans));
  }, [selectedPlanList]);

  useEffect(() => {
    const updatedList = selectedPlanList.slice(
      currentIndex,
      currentIndex + maxVisiblePlans
    );
    setVisiblePlans(updatedList as any);
  }, [currentIndex]);

  const handleSelectPlanType = (type: string) => {
    dispatch(AccountReducerAction.setSelectPlanType(type));
    dispatch(AccountReducerAction.setSelectPlanList(planLists[type]));
  };

  const handleSelectedPlan = (plan: PlanType) => {
    dispatch(AccountReducerAction.setActivePlan(plan));
  };

  const handleGetStarted = (plan: PlanType) => {
    handleSelectedPlan(plan);
  };

  useEffect(() => {
    dispatch(AccountSagaActions.getPlanLists());
    dispatch(
      AccountSagaActions.fetchUserDetails(userDetails?._id ?? user?.user?._id)
    );
  }, []);

  if (isPlanLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1.875rem",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div
      style={{
        margin: "1.875rem 10rem",
      }}
    >
      <HeaderNavigation>
        <div>
          <SwipeLoungeIcon />
        </div>
        <div
          onClick={handleClose}
          style={{
            cursor: "pointer",
          }}
        >
          <CloseSubscriptionIcon />
        </div>
      </HeaderNavigation>

      <MainSection>
        <MainSectionWrapper>
          <MainSectionHeading>Subscription Plans</MainSectionHeading>
        </MainSectionWrapper>
        <MainSectionSubHeadingWrapper>
          <MainSectionSubHeading>
            Annual Plans has more benefits than monthly plans choose the plans
            which suit you better, we will be adding more plans with added
            benefits, look out for discounts on Big Days!
          </MainSectionSubHeading>
        </MainSectionSubHeadingWrapper>
      </MainSection>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "3.125rem",
        }}
      >
        <button
          style={{
            borderRadius: "100px 0px 0px 100px",
            border: "1px solid rgba(255, 255, 255, 0.4)",
            background: `${
              selectedPlanType === monthlyPlanType ? "white" : "transparent"
            }`,
            color: `${
              selectedPlanType === monthlyPlanType ? "black" : "white"
            }`,
            padding: "0.75rem 4.375rem",
            cursor: "pointer",
            fontWeight: 500,
            fontSize: "1.25rem",
          }}
          onClick={() => handleSelectPlanType(monthlyPlanType)}
        >
          Monthly
        </button>
        <button
          style={{
            borderRadius: "0px 100px 100px 0px",
            border: "1px solid rgba(255, 255, 255, 0.4)",
            background: `${
              selectedPlanType === yearlyPlanType ? "white" : "transparent"
            }`,
            color: `${selectedPlanType === yearlyPlanType ? "black" : "white"}`,
            padding: "0.75rem 4.375rem",
            cursor: "pointer",
            fontWeight: 500,
            fontSize: "1.25rem",
          }}
          onClick={() => handleSelectPlanType(yearlyPlanType)}
        >
          Annually
        </button>
      </div>

      <Box display="flex" gap="10px" alignItems="center">
        {currentIndex > 0 && (
          <Box sx={{ cursor: "pointer" }} onClick={handleNavigateLeft}>
            <NavigateLeftIcon />
          </Box>
        )}
        <Grid container spacing={"2.25rem"} paddingInline="3.125rem">
          {visiblePlans?.map((plan: PlanType, index: number) => {
            const planPrice = plan.amount === null ? "Free" : `$${plan.amount}`;

            const gridColumnSize = visiblePlans.length === 2 ? 6 : 4; // Use 6 if 2 items, otherwise 4

            return (
              <Grid item sm={12} md={gridColumnSize} key={plan._id}>
                <CardContainer
                  sx={{
                    // flex: 1,
                    padding: "1.875rem",
                    background: theme.colors.richBlack,
                    border: plan.currentlyActive
                      ? `1px solid ${theme.colors.lightBlue}`
                      : `1px solid rgba(255,255,255,0.4)`,
                    backgroundImage: plan.currentlyActive
                      ? 'url("/assets/images/Ellipse.svg")'
                      : "none",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                    cursor: `${plan.currentlyActive ? "default" : "pointer"}`,
                  }}
                  onClick={() => handleSelectedPlan(plan)}
                >
                  <CardHeaderSection>
                    <CardName>{plan.name}</CardName>
                    <CardPrice>{planPrice}</CardPrice>
                    <CardPlanDescription>
                      {plan.description}
                    </CardPlanDescription>
                  </CardHeaderSection>
                  <CardDetailsWrapper>
                    <CardDetailsHeader>Includes :</CardDetailsHeader>

                    <CardDetailListWrapper>
                      {plan.featureList.map((feature, featureIndex) => (
                        <CardDetailList key={featureIndex}>
                          <CheckedBoxIcon />
                          <CardDetailListText>{feature}</CardDetailListText>
                        </CardDetailList>
                      ))}
                    </CardDetailListWrapper>
                  </CardDetailsWrapper>
                  <CardButtonWrapper
                    style={{
                      justifyContent: plan.currentlyActive
                        ? "space-between"
                        : "center",
                    }}
                  >
                    <CustomButton
                      disabled={plan.currentlyActive}
                      sx={{
                        ":disabled": {
                          color: "white",
                        },
                        padding: "0.813rem 1.25rem",
                        color: theme.colors.white,
                        borderRadius: "100px",
                        border: "1px solid rgba(255,255,255,0.4)",
                        background: plan.currentlyActive
                          ? theme.colors.backgroundPrimary
                          : "none",
                      }}
                      onClick={() => {
                        handleGetStarted(plan);
                        navigate(
                          `${routeConstant?.paymentPreview}/${plan._id}`
                        ); //send selected plan to the preview page
                      }}
                    >
                      {plan.currentlyActive ? "Active" : "Get Started"}
                    </CustomButton>
                    {plan?.currentlyActive && (
                      <CustomButton
                        sx={{
                          ":disabled": {
                            color: "white",
                          },
                          padding: "0.813rem 1.25rem",
                          color: theme.colors.white,
                          borderRadius: "100px",
                          border: "1px solid rgba(255,255,255,0.4)",
                          background: "none",
                        }}
                        onClick={() => {
                          handleGetStarted(plan);
                          navigate(
                            `${routeConstant?.paymentPreview}/${plan._id}`
                          ); //send selected plan to the preview page
                        }}
                      >
                        {"Re Purchase"}
                      </CustomButton>
                    )}
                  </CardButtonWrapper>
                </CardContainer>
              </Grid>
            );
          })}
        </Grid>
        {currentIndex + maxVisiblePlans < selectedPlanList.length && (
          <Box sx={{ cursor: "pointer" }} onClick={handleNavigateRight}>
            <NavigateRightIcon />
          </Box>
        )}
      </Box>
    </div>
  );
};

export default Subscription;
