import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import { ModalLogo } from "../landingPage/navbar/style";
import Logo from "../../assets/images/Logo.png";
import { CheckCircleIcon } from "../../assets/Icons";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { paymentDetailsSelector } from "../../store/payment/selector";
import { PaymentSagaActions } from "../../store/payment/sagas";
import { userDetailsSelector } from "../../store/account/selector";

type InvoiceModalProps = {
  openInvoiceModal: boolean;
  setOpenInvoiceModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedInvoiceId?: string;
};

const InvoiceModal = ({
  openInvoiceModal,
  setOpenInvoiceModal,
  selectedInvoiceId,
}: InvoiceModalProps) => {
  const dispatch = useDispatch();
  const handleCloseModal = () => setOpenInvoiceModal(false);
  const paymentDetails = useSelector(paymentDetailsSelector);
  const user = useSelector(userDetailsSelector);

  useEffect(() => {
    if (openInvoiceModal) {
      dispatch(PaymentSagaActions.getPaymentDetails({ id: selectedInvoiceId }));
    }
  }, [openInvoiceModal]);

  const currentYear = new Date().getFullYear();

  return (
    <Dialog
      onClose={handleCloseModal}
      open={openInvoiceModal && paymentDetails?.payment}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle
        sx={{
          padding: "1.5rem",
          background: "#081720",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <ModalLogo src={Logo} alt="brand-logo" />
      </DialogTitle>
      <DialogContent
        sx={{
          padding: "0.8rem",
          marginBottom: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "15px",
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap="5px"
        >
          <CheckCircleIcon />
          <Typography sx={{ fontSize: "24px", fontWeight: 600 }}>
            Thanks for your order!
          </Typography>
          <Typography sx={{ fontSize: "18px", fontWeight: 500 }}>
            Here’s what you purchased
          </Typography>
        </Box>
        <Box
          sx={{
            textAlign: "center",
            width: "90%",
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",

              lineHeight: "26px",
              textAlign: "center",
            }}
          >
            Hello {user?.fullName},
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              lineHeight: "26px",
              textAlign: "center",
            }}
          >
            Congratulations, You have successfully purchased University Monthly
            Subscription, please find the below order summary details :
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            borderRadius: "10px",
            border: "1px solid #E7E7E7",
            width: "95%",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="15px"
            sx={{
              backgroundColor: "#F9F9F9",
            }}
          >
            <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
              Order Confirmation #
            </Typography>
            <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
              # {paymentDetails?.payment?._id}
            </Typography>
          </Box>
          <Divider sx={{ color: "#E7E7E7" }} />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="15px"
          >
            <Typography sx={{ fontSize: "15px" }}>
              University Monthly Subscription
            </Typography>
            <Typography sx={{ fontSize: "15px" }}>
              ${paymentDetails?.payment?.amount}
            </Typography>
          </Box>
          <Divider sx={{ color: "#E7E7E7", marginX: "10px" }} />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="15px"
          >
            <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
              Total
            </Typography>
            <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
              ${paymentDetails?.payment?.amount}
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          color: "white",
          background:
            "linear-gradient(94.04deg, #1c75bc 2.44%, #00aeef 112.73%)",
          fontWeight: 500,
          fontSize: "16px",
          padding: "1rem",
        }}
      >
        © {currentYear} Swipe Lounge , All Right Reserved
      </Box>
    </Dialog>
  );
};

export default InvoiceModal;
