import {
  ForgotPasswordParamType,
  GoogleLoginParamType,
  LoginParamType,
  ResetPasswordParamType,
  SignUpParamType,
} from "../types/AuthTypes";
import axiosClient from "../apiClient";

export const LoginService = async (params: LoginParamType) => {
  try {
    const response = await axiosClient.post("/auth/login", params);
    return response;
  } catch (error) {
    return error;
  }
};

export const SignUpService = async (params: SignUpParamType) => {
  try {
    const response = await axiosClient.post("/auth/signup", params);
    return response;
  } catch (error) {
    return error;
  }
};

export const GoogleLoginService = async (params: GoogleLoginParamType) => {
  try {
    const response = await axiosClient.post("/auth/social/login", params);
    return response;
  } catch (error) {
    return error;
  }
};

export const logoutService = async () => {
  try {
    const response = await axiosClient.get("/auth/logout");
    return response;
  } catch (error) {
    return error;
  }
};

export const ForgotPasswordService = async (
  params: ForgotPasswordParamType
) => {
  try {
    const response = await axiosClient.post("/auth/forgotPassword", params);
    return response;
  } catch (error) {
    return error;
  }
};

export const ResetPasswordService = async (params: ResetPasswordParamType) => {
  try {
    const response = await axiosClient.post("/auth/resetPassword", params);
    return response;
  } catch (error) {
    return error;
  }
};

export const ResendVerifyEmail = async (params: ForgotPasswordParamType) => {
  try {
    const response = await axiosClient.post("/auth/resend-verifyEmail", params);
    return response;
  } catch (error) {
    return error;
  }
};

export const verifyEmailService = async (params: string) => {
  try {
    const response = await axiosClient.get(`/auth/verifyEmail/${params}`);
    return response;
  } catch (error) {
    return error;
  }
};
